import { Box, Grid } from "@material-ui/core"
import { AccountSettingIcon, NotificationIcon } from "../../libs/icons"
import { useState } from "react"
import Notification from "../../components/setting/Notification"
import AccountSetting from "../../components/setting/AccountSetting"
import NCText from "../../shared/NCText"
import NCSvgIcon from "../../shared/NCSvgIcon"

const Setting = () => {
  const Items = [
    {
      icon: AccountSettingIcon,
      name: "Account Settings",
      component: <AccountSetting />
    },
    {
      icon: NotificationIcon,
      name: "Notifications",
      component: <Notification />
    }
  ]
  const [selectedItem, setSelectedItem] = useState(Items[0])
  return (
    <Grid container justifyContent="center">
      <Grid item md={8} container>
        <Grid item xs={12}>
          <NCText variant={"h4"}>Settings</NCText>
          <hr />
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item>
            {Items.map(item => (
              <Box
                onClick={() => setSelectedItem(item)}
                bgcolor={
                  item.name === selectedItem.name ? "#EFFDFF" : "transparent"
                }
                borderLeft={
                  item.name === selectedItem.name ? "2px solid #1EAAC4" : "none"
                }
                p={2}
                className="cursor-pointer"
              >
                <Box display={"flex"} gridGap={"5px"}>
                  <NCSvgIcon component={item.icon} inheritViewBox />
                  <NCText variant={"body2"}>{item.name}</NCText>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item style={{ flexGrow: 1, display: "flex" }}>
            <Box bgcolor={"#F8F9FA"} p={1} flexGrow={1} minHeight={"80%"}>
              {selectedItem.component}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Setting
