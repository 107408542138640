import { Grid, Box, makeStyles, Button } from "@material-ui/core"
import NCText from "../../shared/NCText"
import FormSection from "../../components/FormSection"
import {
  useGetClientDetail
} from "../../services/queries"
import { GENDER } from "../../libs/config"
import {
  formatDate,
  getAddressValue,
  getAge,
  loggedInUser
} from "../../libs/utils"
import GuardianList from "./GuardianList"
import NCLoader from "../../shared/NCLoader"
import { useState } from "react"
import EditClientModal from "./EditClientModal"
import EditEmergencyModal from "./EditEmergencyModal"
import EditSubAdminModal from "./EditSubAdminModal"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { DriverEditIcon } from "../../libs/icons"
import NCTooltip from "../../shared/NCTooltip"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px",
    width: "180px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between"
  },
  subSectionTitle: {
    color: "#9CA3AF",
    fontSize: "12px"
  },
  "& .MuiBox-root": {
    display: "flex"
  }
}))
const SelectedClient = ({ clientId }) => {
  const cls = useStyles()
  const user = loggedInUser()
  const [clientModal, setClientModal] = useState(false)
  const [emergencyModal, setEmergencyModal] = useState(false)
  const [subAdminModal, setSubAdminModal] = useState(false)
  const { isLoading, data } = useGetClientDetail(clientId)
  const result = data?.data?.data || {}
  // const { isLoading, data: subAdminData } = useGetSubAdminDetail(
  //   result.subadmin,
  //   {
  //     enabled: user.user_type === 3 ? false : result.subadmin ? true : false
  //   }
  // )
  // let subAdmin
  // let adminName
  // if (user.user_type === 3) {
  //   subAdmin = user.profile_attributes || {}
  //   adminName = subAdmin.admin?.company_name || ""
  // } else {
  let subAdmin = result?.client_subadmin_info || {} //|| subAdminData?.data?.data || {}
  let adminName = result?.client_subadmin_info?.admin?.company_name || "" //|| subAdmin?.admin?.company_name || ""
  //}

  const renderRowItem = (key, value) => {
    return (
      <Grid item md={4}>
        <NCText>
          <Box fontSize={"12px"} color={"#9CA3AF"}>
            {key}
          </Box>
        </NCText>
        <NCText variant="body2" style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {value}
        </NCText>
      </Grid>
    )
  }

  const clientTitle = (
    <Grid item className={cls.titleSection}>
      User Details
      {user.user_type === 1 || user.user_type === 2 ? <Button
        onClick={() => setClientModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        Edit Client
      </Button> : null}
    </Grid>
  )
  const subAdminTitle = (
    <Grid item className={cls.titleSection}>
      Sub Admin (School) Details
      {user.user_type === 1 || user.user_type === 2 ? (
        <Button
          onClick={() => setSubAdminModal(true)}
          className={cls.button}
          variant="contained"
          color="primary"
          startIcon={<NCSvgIcon component={DriverEditIcon} inheritViewBox />}
        >
          Sub Admin/School
        </Button>
      ) : null}
    </Grid>
  )
  const emergencyTitle = (
    <Grid item className={cls.titleSection}>
      Emergency Contact Details
      {user.user_type === 1 || user.user_type === 2 ? <Button
        onClick={() => setEmergencyModal(true)}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        Edit Emergency Contact
      </Button> : null}
    </Grid>
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8} md={12}>
        <FormSection title={clientTitle}>
          <Box mb={2}>
            <Grid container spacing={2}>
              {renderRowItem("First Name", result.first_name)}
              {renderRowItem("Middle Name", result.middle_name)}
              {renderRowItem("Last Name", result.last_name)}
              {/* <NCTooltip title={result.email || ""}> */}
              {result.email ? (
                <NCTooltip title={result.email || ""}>
                  {renderRowItem("Email address", result.email)}
                </NCTooltip>
              ) : null}
              {/* </NCTooltip> */}
              {renderRowItem("Phone number", result.phone)}
              {renderRowItem("Gender", GENDER[result.gender])}

              {renderRowItem("Date of birth", formatDate(result.dob))}
              {renderRowItem("Age", getAge(result.dob))}
              {renderRowItem("Address", getAddressValue(result.address))}

              {renderRowItem("Student ID", getAddressValue(result.identifier))}
            </Grid>
          </Box>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={8} md={12}>
        <FormSection title={subAdminTitle}>
          <Grid container>
            {renderRowItem("Name", subAdmin.name)}
            {renderRowItem("Admin", adminName)}
          </Grid>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={8} md={12}>
        <FormSection title={emergencyTitle}>
          <Grid container>
            {renderRowItem(
              "Emergency Contact Name",
              result?.emergency_contact?.name
            )}
            <NCTooltip title={result?.emergency_contact?.email || ""}>
              {renderRowItem(
                "Emergency Contact Email",
                result?.emergency_contact?.email
              )}
            </NCTooltip>
            {renderRowItem("Phone number", result?.emergency_contact?.phone)}
          </Grid>
        </FormSection>
      </Grid>
      <Grid item xs={12} sm={8} md={12}>
        <GuardianList clientId={clientId} />
      </Grid>
      {isLoading && <NCLoader open={true} />}
      <EditClientModal
        clientId={clientId}
        modalState={clientModal}
        handleClose={() => setClientModal(false)}
        data={result}
      />
      <EditEmergencyModal
        clientId={clientId}
        modalState={emergencyModal}
        handleClose={() => setEmergencyModal(false)}
        data={result?.emergency_contact}
      />
      {subAdmin.id && (user.user_type === 1 || user.user_type === 2) ? (
        <EditSubAdminModal
          clientId={clientId}
          modalState={subAdminModal}
          handleClose={() => setSubAdminModal(false)}
          data={{
            subAdmin: subAdmin.id,
            name: subAdmin.name,
            adminName,
            clientId
          }}
        />
      ) : null}
    </Grid>
  )
}
export default SelectedClient
