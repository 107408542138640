import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Grid } from "@material-ui/core"
import { useAddGuardian, useUpdateGuardian } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../FormField"
import PhoneNumber from "../PhoneNumber"
import { useQueryClient } from "react-query"

const AddGuardianModal = ({
  clientId,
  modalState = false,
  handleClose,
  isEdit = false,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const addGuardianRequest = isEdit
    ? useUpdateGuardian(data.id)
    : useAddGuardian()
  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      phone: data.phone || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string().required("Email is required"),
      phone: Yup.string().required("Phone number is required")
    }),
    enableReinitialize: true,
    onSubmit(values) {
      addGuardianRequest.mutate(
        { ...values, client: clientId, id: data.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getGuardianList"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            isEdit
              ? toast.success("Guardian has been updated successfully")
              : toast.success("Guardian has been added successfully")
            handleClose()
            !isEdit && formik.resetForm()
          }
        }
      )
    }
  })
  const guardianModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormField
              label={"First Name"}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={"Last Name"}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              label={"Email Address"}
              name={"email"}
              required={true}
              disabled={isEdit}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormField
              as={PhoneNumber}
              label={"Phone Number"}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={isEdit ? "Update Guardian" : "Add Guardian"}
      text={guardianModal}
      onClose={() => handleClose()}
      submitButton={
        <NCButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={formik.handleSubmit}
          style={{ width: "auto" }}
        >
          Submit
        </NCButton>
      }
    />
  )
}
export default AddGuardianModal
