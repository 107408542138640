import { Modal, makeStyles, Box } from "@material-ui/core"
import FormField from "./FormField"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import NCButton from "../shared/NCButton"
import { useChangePassword } from "../services/mutations"
import { toast } from "react-toastify"
import NCText from "../shared/NCText"
import { useNavigate } from "react-router-dom"
import NCPasswordInput from "../shared/NCPasswordInput"

const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    backgroundColor: "white",
    boxShadow: 24,
    padding: "20px 30px",
    borderRadius: "5px"
  },
  cancelButton: {
    borderRadius: "30px",
    fontSize: "14px",
    marginRight: "10px",
    lineHeight: "16px",
    color: "#005B90",
    borderColor: "#005B90"
  },
  discardButton: {
    borderRadius: "30px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "white",
    backgroundColor: "red"
  }
}))
const ChangePasswordModal = props => {
  const cls = useStyles()
  const changePasswordRequest = useChangePassword()
  const nav = useNavigate()
  const formik = useFormik({
    initialValues: {
      old_values: "",
      new_password1: "",
      new_password2: ""
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required("Password is required"),
      new_password1: Yup.string().required("Password is required"),
      new_password2: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
    }),
    onSubmit(values) {
      changePasswordRequest.mutate(values, {
        onSuccess: () => {
          toast.success("Password changed successfully")
          nav("/logout")
        }
      })
    }
  })
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box className={cls.root}>
        <NCText variant={"h5"}>Change Password</NCText>
        <Box mt={2}>
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <FormField
                as={NCPasswordInput}
                label={"Old Password"}
                name={"old_password"}
                required={true}
                type="password"
                error={formik?.errors?.old_password}
              />
              <FormField
                as={NCPasswordInput}
                label={"New Password"}
                name={"new_password1"}
                required={true}
                type="password"
                error={formik?.errors?.new_password1}
              />
              <FormField
                as={NCPasswordInput}
                label={"Confirm Password"}
                name={"new_password2"}
                required={true}
                type="password"
                error={formik?.errors?.new_password2}
              />
              <NCButton type="submit" variant="contained" color="primary">
                Change Password
              </NCButton>
            </Form>
          </FormikProvider>
        </Box>
      </Box>
    </Modal>
  )
}

export default ChangePasswordModal
