import { useQuery } from "react-query"
import API from "../libs/api"
import { API_PATH } from "../libs/config"

let default_config = {
  refetchOnWindowFocus: false,
  enabled: true
}

export const useGetPrivacyPolicy = () => {
  return useQuery(["getPrivacyPolicy"], () => API.get(API_PATH.PRIVACY_POLICY))
}
export const useGetTermsAndConditions = () => {
  return useQuery(["getTermsandConditions"], () =>
    API.get(API_PATH.TERMS_AND_CONDITIONS)
  )
}
export const useGetClients = (page, search, size, params, config) => {
  return useQuery(
    ["getClientList", page, size, search, params],
    () =>
      API.get(API_PATH.CLIENT_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}
export const useGetClientDetail = (id, config) => {
  return useQuery(
    ["getClientDetail", id],
    () => API.get(`${API_PATH.CLIENT_LIST}${id}/`),
    { ...default_config, ...config }
  )
}

export const useGetClientsFile = (filetype, search, params, config) => {
  return useQuery(
    ["getClientFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.CLIENT_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetOverview = (page, search, size, params, config) => {
  return useQuery(
    ["getOverviewList", page, size, search, params],
    () =>
      API.get(API_PATH.CLIENT_REQUEST_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetOverviewFile = (filetype, search, params, config) => {
  return useQuery(
    ["getOverviewFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.CLIENT_REQUEST_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAdminEmployee = (page, search, size, params, config) => {
  return useQuery(
    ["getAdminEmployeeList", page, size, search, params],
    () =>
      API.get(API_PATH.ADMIN_EMPLOYEE_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}
export const useGetAdminEmployeeFile = (filetype, search, params, config) => {
  return useQuery(
    ["getAdminEmployeeFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.ADMIN_EMPLOYEE_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}
export const useGetSubAdmins = (page, search, size, params, config) => {
  return useQuery(
    ["getSubAdminList", page, size, search, params],
    () =>
      API.get(API_PATH.SUB_ADMIN_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetSubAdminFile = (filetype, search, params, config) => {
  return useQuery(
    ["getSubAdminFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.SUB_ADMIN_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetSubEmployees = (page, search, size, params, config) => {
  return useQuery(
    ["getSubEmployeeList", page, size, search, params],
    () =>
      API.get(API_PATH.SUB_EMPLOYEE_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetSubEmployeesFile = (filetype, search, params, config) => {
  return useQuery(
    ["getSubEmployeeFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.SUB_EMPLOYEE_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetSubAdminDetail = (id, config) => {
  return useQuery(
    ["getSubAdminDetail", id],
    () => API.get(`${API_PATH.SUB_ADMIN_LIST}${id}/`),
    { ...default_config, ...config }
  )
}

export const useGetEmployees = (page, search, size, params, config) => {
  return useQuery(
    ["getEmployeesList", page, size, search, params],
    () =>
      API.get(API_PATH.EMPLOYEE_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetRepresentativeDetail = id => {
  return useQuery(
    "getRepresentativeDetail",
    () => API.get(`${API_PATH.REPRESENTATIVE_LIST}?admin=${id}`),
    {
      ...default_config
    }
  )
}

export const useGetAdmin = (page, search, size, params, config) => {
  return useQuery(
    ["getAdminList", page, size, search, params],
    () =>
      API.get(API_PATH.ADMIN_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    {
      ...default_config,
      ...config
    }
  )
}

export const useGetAdminFile = (filetype, search, params, config) => {
  return useQuery(
    ["getAdminFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.ADMIN_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAdminDetail = id => {
  return useQuery(
    "getAdminDetail",
    () => API.get(`${API_PATH.ADMIN_LIST}${id}/`),
    {
      ...default_config
    }
  )
}

export const useGetClientRequest = (page, search, size, params, config) => {
  return useQuery(
    ["getClientRequests", page, size, search, params],
    () =>
      API.get(API_PATH.CLIENT_REQUEST_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetUserTips = () => {
  return useQuery("getUserTips", () => API.get(API_PATH.USER_TIPS))
}

export const useGetVehicleRequirements = () => {
  return useQuery("getVehicleRequirements", () =>
    API.get(API_PATH.VEHICLE_REQUIREMENTS)
  )
}

export const useGetServiceDetails = () => {
  return useQuery("getServiceDetails", () => API.get(API_PATH.SERVICE_DETAILS))
}

export const useGetOtherRequirements = () => {
  return useQuery("getOtherRequirements", () =>
    API.get(API_PATH.OTHER_REQUIREMENTS)
  )
}

export const useGetClientRequestDetail = id => {
  return useQuery("getClientRequestDetail", () =>
    API.get(`${API_PATH.CLIENT_REQUESTS}${id}/`)
  )
}

export const useGetFeedbackList = (page, search, size, params, config) => {
  return useQuery(
    ["getFeedbackList", page, size, search, params],
    () =>
      API.get(API_PATH.GET_FEEDBACK_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetFeedbackDetail = (id, config) => {
  return useQuery(
    ["getFeedbackDetail", id],
    () =>
      API.get(API_PATH.GET_FEEDBACK_REPLIES, {
        params: { feedback: id }
      }),
    { ...default_config, ...config }
  )
}

export const useGetGuardianList = (page, search, size, params, config) => {
  return useQuery(
    ["getGuardianList", page, size, search, params],
    () =>
      API.get(API_PATH.GET_GUARDIAN_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetEmergencyDetail = () => {
  return useQuery(["getEmergencyDetail"], data =>
    API.get(`${API_PATH.EMERGENCY_CONTACT}${data.id}/`)
  )
}

export const useGetNotificationSetting = () => {
  return useQuery(
    "getNotificationSetting",
    () => API.get(API_PATH.NOTIFICATION_SETTING),
    {
      ...default_config
    }
  )
}

export const useGetNotification = (page, size, params, config) => {
  return useQuery(
    ["getNotificationData", page, size, params],
    () =>
      API.get(API_PATH.NOTIFICATION, {
        params: { page, page_size: size, ...params }
      }),
    {
      ...default_config,
      ...config
    }
  )
}
export const useGetNotificationSummary = (params, config) => {
  return useQuery(
    ["getNotificationSummary", params],
    () => API.get(API_PATH.NOTIFICATION_SUMMARY, {
      params
    }),
    {
      ...default_config,
      ...config
    }
  )
}
export const useGetDrivers = (page, search, size, params, config) => {
  return useQuery(
    ["getDriverList", page, size, search, params],
    () =>
      API.get(API_PATH.DRIVER, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDriverDetail = (id, config) => {
  return useQuery(
    ["getDriverDetail", id],
    () => API.get(`${API_PATH.DRIVER}${id}/`),
    { ...default_config, ...config }
  )
}

export const useGetDriversFile = (filetype, search, params, config) => {
  return useQuery(
    ["getDriverFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.DRIVER}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDispatchersList = (page, search, size, params, config) => {
  return useQuery(
    ["getDispatcherList", page, size, search, params],
    () =>
      API.get(API_PATH.DISPATCHER_LIST, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDispatcher = id => {
  return useQuery(["getDispatcher"], () => {
    return API.get(`${API_PATH.DISPATCHER_LIST}${id}/`)
  })
}

export const useGetDispatchersFile = (filetype, search, params, config) => {
  return useQuery(
    ["getDispatcherFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.DISPATCHER_LIST}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAidMonitorList = (page, search, size, params, config) => {
  return useQuery(
    ["getAidMonitorList", page, size, search, params],
    () =>
      API.get(API_PATH.AID_MONITOR, {
        params: { page, page_size: size, search, ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAidMonitor = id => {
  return useQuery(["getAidMonitor"], () => {
    return API.get(`${API_PATH.AID_MONITOR}${id}/`)
  })
}

export const useGetAidMonitorFile = (filetype, search, params, config) => {
  return useQuery(
    ["getAidMonitorFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.AID_MONITOR}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}

//chamdan mishra
export const useGetClientAuditLog = (params, config) => {
  return useQuery(
    ["getClientAuditLog", params],
    () =>
      API.get(API_PATH.CLIENT_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAdminAuditLog = (params, config) => {
  return useQuery(
    ["getAdminAuditLog", params],
    () =>
      API.get(API_PATH.ADMIN_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetSubAdminAuditLog = (params, config) => {
  return useQuery(
    ["getSubAdminAuditLog", params],
    () =>
      API.get(API_PATH.SUBADMIN_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetRequestAuditLog = (params, config) => {
  return useQuery(
    ["getRequestAuditLog", params],
    () =>
      API.get(API_PATH.REQUEST_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDriverAuditLog = (params, config) => {
  return useQuery(
    ["getDriverAuditLog", params],
    () =>
      API.get(API_PATH.DRIVER_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDispatcherAuditLog = (params, config) => {
  return useQuery(
    ["getDispatcherAuditLog", params],
    () =>
      API.get(API_PATH.DISPATCHER_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetAidMonitorAuditLog = (params, config) => {
  return useQuery(
    ["getAidMonitorAuditLog", params],
    () =>
      API.get(API_PATH.AID_MONITOR_AUDIT_LOG, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDriverAppointments = (params, config) => {
  return useQuery(
    ["getDriverAppointments", params],
    () =>
      API.get(API_PATH.DRIVER_APPOINTMENT, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDriverAvailability = (params, config) => {
  return useQuery(
    ["getDriverAvailability", params],
    () =>
      API.get(API_PATH.DRIVER_AVAILABILITY, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetDriverByID = id => {
  return useQuery(["getDriverById"], () => {
    return API.get(`${API_PATH.DRIVER_AVAILABILITY}${id}/`)
  })
}

export const useGetDriverList = search => {
  return useQuery(
    ["getDriverList", search],
    () =>
      API.get(API_PATH.DRIVER_LIST, {
        params: { search }
      }),
    {
      ...default_config
    }
  )
}

export const useGetRideDetails = (params, config) => {
  return useQuery(
    ["getRideDetails", params],
    () =>
      API.get(API_PATH.TRIPS, {
        params: { ...params }
      }),
    { ...default_config, ...config }
  )
}

export const useGetVehiclesList = (page, search, size, params, config) => {
  return useQuery(
    ["getVehicleList", page, size, search, params],
    () =>
      API.get(API_PATH.VEHICLES, {
        params: { page, page_size: size, search, ...params }
      }),
    {
      ...default_config,
      ...config
    }
  )
}

export const useGetVehiclesFile = (filetype, search, params, config) => {
  return useQuery(
    ["getVehicleFile", filetype, search, params],
    () =>
      API.get(`${API_PATH.VEHICLES}${filetype}/`, {
        responseType: "arraybuffer",
        params: { search, ...params }
      }),
    { ...default_config, ...config }
  )
}
