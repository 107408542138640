import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    height: "10px",
    width: "10px",
    backgroundColor: props => {
      if (
        props?.status?.toLowerCase() === "inactive" ||
        props?.status?.toLowerCase() === "declined"
      ) {
        return "#E22134"
      } else if (
        props?.status?.toLowerCase() === "active" ||
        props?.status?.toLowerCase() === "approved" ||
        props?.status?.toLowerCase() === "verified"
      ) {
        return "#1DB954"
      } else if (
        props?.status?.toLowerCase() === "pending" ||
        props?.status?.toLowerCase() === "on hold"
      ) {
        return "#FF9800"
      }
    },
    borderRadius: "50%",
    display: "inline-block",
    marginRight: "5px"
  }
})

const NCTableStatus = props => {
  const cls = useStyles(props)
  return (
    <>
      <div className={cls.root} />
      {props.status}
    </>
  )
}

export default NCTableStatus
