import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateClient } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import { GENDER } from "../../libs/config"
import PhoneNumber from "../PhoneNumber"
import { useEffect } from "react"
import { getAge } from "../../libs/utils"
import NCAddress from "../../shared/NCAddress"
import NCDatePicker from "../../shared/NCDatePicker"
import NCAutocomplete from "../../shared/NCAutocomplete"

const EditClientModal = ({
  clientId,
  modalState = false,
  handleClose,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const updateClientApi = useUpdateClient()
  const formik = useFormik({
    initialValues: {
      first_name: data.first_name || "",
      middle_name: data.middle_name || "",
      last_name: data.last_name || "",
      email: data.email || "",
      phone: data.phone || "",
      gender: data.gender || "",
      dob: data.dob || "",
      age: data.age || "",
      address: data.address || ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address"),
      phone: Yup.string().required("Phone Number is required"),
      gender: Yup.string().required("Gender is required"),
      dob: Yup.date().typeError("Use format(MM/dd/YYYY)").required("Dob is required"),
      address: Yup.string().required("Address is required")
    }),
    enableReinitialize: true,
    onSubmit(values) {
      updateClientApi.mutate(
        { ...values, id: clientId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getClientDetail"])
            queryClient.invalidateQueries(["getClientAuditLog"])
            toast.success("Client details edited successfully")
            handleClose()
          }
        }
      )
    }
  })
  useEffect(() => {
    if (formik.values.dob) {
      const age = getAge(formik.values.dob)
      formik.setFieldValue("age", age)
    }
  }, [formik.values.dob])
  const clientModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormField
              label={"First Name"}
              name={"first_name"}
              required={true}
              error={formik?.errors?.first_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={"Middle Name"}
              name={"middle_name"}
              error={formik?.errors?.middle_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={"Last Name"}
              name={"last_name"}
              required={true}
              error={formik?.errors?.last_name}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={"Email Address"}
              name={"email"}
              error={formik?.errors?.email}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={PhoneNumber}
              label={"Phone Number"}
              name={"phone"}
              onChange={e => formik.setFieldValue("phone", `+${e}`)}
              required={true}
              error={formik?.errors?.phone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCAutocomplete}
              options={
                Object.keys(GENDER).map(item => {
                  return { id: item, name: GENDER[item] }
                }) || []
              }
              label={"Gender"}
              name={"gender"}
              onChange={v => {
                formik.setFieldValue("gender", v)
              }}
              required={true}
              error={formik?.errors?.gender}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCDatePicker}
              label={"Date of Birth"}
              name={"dob"}
              onChange={v => formik.setFieldValue("dob", v)}
              max={new Date().toISOString().split("T")[0]}
              required={true}
              error={formik?.errors?.dob}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              label={"Age"}
              name={"age"}
              required={true}
              readOnly={true}
              error={formik?.errors?.age}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormField
              as={NCAddress}
              label={"Address"}
              name={"address"}
              onChange={e => formik.setFieldValue("address", e)}
              required={true}
              error={formik?.errors?.address}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={"Edit Client"}
        text={clientModal}
        onClose={() => handleClose()}
        size={"lg"}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            Update
          </NCButton>
        }
      />
      {updateClientApi.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditClientModal
