import { Box, Grid } from "@material-ui/core"
// import NCText from "../../shared/NCText"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import { FieldArray, Form, FormikProvider, useFormik } from "formik"
import AddProfileIcon from "../../assets/icons/add_profile_image.svg"
import { GENDER } from "../../libs/config"
import PhoneNumber from "../../components/PhoneNumber"
import * as Yup from "yup"
import NCButton from "../../shared/NCButton"
import { AddIcon, RemoveItemIcon } from "../../libs/icons"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { useEffect } from "react"
import { getAge } from "../../libs/utils"
import { useGetClientDetail, useGetSubAdmins } from "../../services/queries"
import { useAddClient, useUpdateClient } from "../../services/mutations"
import { toast } from "react-toastify"
import { useState } from "react"
import { useQueryClient } from "react-query"
// import TopNavBar from "../../components/TopNavBar"
import NCLoader from "../../shared/NCLoader"
import { useNavigate, useParams } from "react-router-dom"
import NCAddress from "../../shared/NCAddress"
import NCDatePicker from "../../shared/NCDatePicker"
import NCAutocomplete from "../../shared/NCAutocomplete"
import FormTemplate from "../../components/FormTemplate"

const AddClient = ({ edit = false }) => {
  const { clientId } = useParams()
  const nav = useNavigate()
  const [preview, setPreview] = useState()
  const [search, setSearch] = useState(null)
  const { data, isLoading } = useGetSubAdmins(null, search)
  const addClientApi = edit ? useUpdateClient() : useAddClient()
  const subAdmins = data?.data?.data?.results || []
  const { data: clientResult } = clientId ? useGetClientDetail(clientId) : {}
  const clientData = clientResult?.data?.data || {}
  const queryClient = useQueryClient()
  const [adminName, setAdminName] = useState("")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      avatar: clientData?.avatar || null,
      first_name: clientData?.first_name || "",
      middle_name: clientData?.middle_name || "",
      last_name: clientData?.last_name || "",
      email: clientData?.email || "",
      phone: clientData?.phone || "",
      gender: clientData?.gender || "",
      dob: clientData?.dob || "",
      age: clientData?.age || "",
      address: clientData?.address || "",
      subadmin: clientData?.subadmin || "",
      emergency_contact: {
        email: clientData?.emergency_contact?.email || "",
        phone: clientData?.emergency_contact?.phone || "",
        name: clientData?.emergency_contact?.name || ""
      },
      guardian: (clientData && clientData.guardian) || [
        {
          email: "",
          phone: "",
          first_name: "",
          last_name: ""
        }
      ]
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("First Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address"),
      phone: Yup.string().required("Phone Number is required"),
      gender: Yup.string().required("Gender is required"),
      dob: Yup.date().typeError("Use format(MM/dd/YYYY)").required("Dob is required"),
      address: Yup.string().required("Address is required"),
      identifier: Yup.string().required("Student ID is required"),
      subadmin: Yup.string().required("Sub Admin (School) is required"),
      emergency_contact: Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address")
          .required("Emergency Contact email is required"),
        phone: Yup.string().required("Emergency Contact phone is required"),
        name: Yup.string().required("Emergency Contact name is required")
      }),
      guardian: Yup.array()
        .of(
          Yup.object().shape({
            first_name: Yup.string().required(
              "Guardian First name is required"
            ),
            last_name: Yup.string().required("Guardian Last name is required"),
            phone: Yup.string().required("Guardian Contact phone is required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Guardian Contact email is required")
          })
        )
        // .required("Must have guardian")
        .min(1, "Minimum of 1 guardian")
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedValues = { ...values }
      if (clientId) {
        updatedValues.id = clientId
      }
      addClientApi.mutate(updatedValues, {
        onSuccess: async () => {
          toast.success("Client has been added successfully")
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getClientList"])
          nav("/clients")
        }
      })
    }
  })
  useEffect(() => {
    if (formik.values.dob) {
      const age = getAge(formik.values.dob)
      formik.setFieldValue("age", age)
    }
  }, [formik.values.dob])
  useEffect(() => {
    const obj = subAdmins?.filter(item => item.id == formik.values.subadmin)
    setAdminName(obj?.[0]?.admin?.company_name)
  }, [formik.values.subadmin])

  const footerButtons = (
    <>
      <Grid item>
        <NCButton
          variant="outlined"
          color="secondary"
          onClick={() => nav("/clients")}
        >
          Cancel
        </NCButton>
      </Grid>
      <Grid item>
        <NCButton
          type="submit"
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          {edit ? "Update" : "Save"}
        </NCButton>
      </Grid>
    </>
  )
  return (
    <FormTemplate title="Add Client" footerButtons={footerButtons}>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4} md={2}>
                <label htmlFor="upload-photo">
                  <input
                    style={{ display: "none" }}
                    id="upload-photo"
                    name="upload-photo"
                    type="file"
                    onChange={e => {
                      setPreview(URL.createObjectURL(e.target.files[0]))
                      formik.setFieldValue("avatar", e.target.files[0])
                    }}
                  />
                  <Box
                    // width={"150px"}
                    // height={"150px"}
                    className="cursor-pointer"
                  >
                    {preview || formik?.values?.avatar ? (
                      <img
                        src={preview || formik?.values?.avatar}
                        width={"100%"}
                        style={{ borderRadius: "100%" }}
                        htmlFor="contained-button-file"
                      />
                    ) : (
                      <img
                        src={AddProfileIcon}
                        width={"100%"}
                        htmlFor="contained-button-file"
                      />
                    )}
                  </Box>
                </label>
              </Grid>
              <Grid item xs={12} sm={8} md={10}>
                <FormSection title="Client Details">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"First Name"}
                        name={"first_name"}
                        required={true}
                        error={formik?.errors?.first_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Middle Name"}
                        name={"middle_name"}
                        error={formik?.errors?.middle_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Last Name"}
                        name={"last_name"}
                        required={true}
                        error={formik?.errors?.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Email Address"}
                        name={"email"}
                        error={formik?.errors?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={PhoneNumber}
                        label={"Phone Number"}
                        name={"phone"}
                        onChange={e => formik.setFieldValue("phone", `+${e}`)}
                        required={true}
                        error={formik?.errors?.phone}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAutocomplete}
                        options={
                          Object.keys(GENDER).map(item => {
                            return { id: item, name: GENDER[item] }
                          }) || []
                        }
                        label={"Gender"}
                        name={"gender"}
                        onChange={v => {
                          formik.setFieldValue("gender", v)
                        }}
                        required={true}
                        error={formik?.errors?.gender}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCDatePicker}
                        label={"Date of Birth"}
                        name={"dob"}
                        onChange={v => formik.setFieldValue("dob", v)}
                        max={new Date().toISOString().split("T")[0]}
                        required={true}
                        error={formik?.errors?.dob}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Age"}
                        name={"age"}
                        readOnly={true}
                        error={formik?.errors?.age}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAddress}
                        label={"Address"}
                        name={"address"}
                        onChange={e => formik.setFieldValue("address", e)}
                        required={true}
                        error={formik?.errors?.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Student ID"}
                        name={"identifier"}
                        required={true}
                        error={formik?.errors?.identifier}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title="Sub Admin (School)">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={NCAutocomplete}
                        options={subAdmins || []}
                        label={"Select Sub Admin (School)"}
                        name={"subadmin"}
                        onChange={v => {
                          formik.setFieldValue("subadmin", v)
                        }}
                        loading={isLoading}
                        onSearch={v => setSearch(v)}
                        required={true}
                        error={formik?.errors?.subadmin}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Admin"}
                        name={"admin"}
                        value={adminName}
                        readOnly={true}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FormSection title="Add Emergency Contact">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Emergency Contact Name"}
                        name={"emergency_contact.name"}
                        required={true}
                        error={formik?.errors?.emergency_contact?.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        label={"Emergency Contact Email"}
                        name={"emergency_contact.email"}
                        required={true}
                        error={formik?.errors?.emergency_contact?.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormField
                        as={PhoneNumber}
                        label={"Phone No"}
                        name={"emergency_contact.phone"}
                        onChange={e =>
                          formik.setFieldValue(
                            "emergency_contact.phone",
                            `+${e}`
                          )
                        }
                        required={true}
                        error={formik?.errors?.emergency_contact?.phone}
                      />
                    </Grid>
                  </Grid>
                </FormSection>
                <FieldArray
                  name="guardian"
                  render={arrayHelpers => (
                    <FormSection
                      title={
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Grid item>
                            <span>Guardians</span>
                          </Grid>
                          <Grid item>
                            <NCButton
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                arrayHelpers.push({
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  phone: ""
                                })
                              }
                              endIcon={
                                <NCSvgIcon component={AddIcon} inheritViewBox />
                              }
                            >
                              Add Guardian
                            </NCButton>
                          </Grid>
                        </Grid>
                      }
                    >
                      <Grid container spacing={2}>
                        {formik.values.guardian &&
                          formik.values.guardian.length > 0
                          ? formik.values.guardian.map((guard, index) => (
                            <>
                              <Grid item container spacing={1} xs={11}>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"First Name"}
                                    name={`guardian[${index}].first_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]
                                        ?.first_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"Last Name"}
                                    name={`guardian[${index}].last_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]
                                        ?.last_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"Contact email"}
                                    name={`guardian[${index}].email`}
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]?.email
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    as={PhoneNumber}
                                    label={"Phone No"}
                                    name={`guardian[${index}].phone`}
                                    onChange={e =>
                                      formik.setFieldValue(
                                        `guardian[${index}].phone`,
                                        `+${e}`
                                      )
                                    }
                                    required={true}
                                    error={
                                      formik?.errors?.guardian?.[index]?.phone
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                  mt={"30px"}
                                >
                                  <RemoveItemIcon
                                    onClick={() => arrayHelpers.remove(index)}
                                    className="cursor-pointer"
                                  />
                                </Box>
                              </Grid>
                            </>
                          ))
                          : ""}
                      </Grid>
                    </FormSection>
                  )}
                ></FieldArray>
              </Grid>
            </Grid>
          </Grid>

          {addClientApi.isLoading && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
    </FormTemplate>

    // </Grid>
  )
}

export default AddClient
