/* eslint-disable no-use-before-define */
import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { TextField, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  select: {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      padding: "0",
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px !important"
    }
  },
  noFill: {
    color: "red"
  }
}))
const NCAutocomplete = props => {
  const getSelectedOption = () => {
    let value = props.options.find(item => item.id === props.value)
    return value || ""
  }
  const cls = useStyles()
  return (
    <Autocomplete
      {...props}
      id="combo-box-demo"
      options={props.options || []}
      disableClearable={true}
      getOptionLabel={option => option.name}
      value={getSelectedOption()}
      onChange={(e, newValue) => props.onChange(newValue?.id)}
      renderInput={params => (
        <TextField
          {...props}
          {...params}
          className={cls.select}
          onChange={(e) => {
            if (props.onSearch && e.target.value) {
              props.onSearch(e.target.value)
            }
            props.onChange(e.target.value)

          }}
          variant="outlined"
        />
      )}
    />
  )
}
export default NCAutocomplete
