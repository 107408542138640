import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  input: {
    borderRadius: "20px",
    width: "100%",
    textTransform: "capitalize"
  }
})
const NCButton = props => {
  const cls = useStyles()
  return (
    <Button {...props} className={`${cls.input} ${props.className}`}>
      {props.children}
    </Button>
  )
}

export default NCButton
