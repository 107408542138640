import { Box, Grid } from "@material-ui/core"
import NCButton from "../../shared/NCButton"
import { OpenModalIcon } from "../../libs/icons"
import NCSvgIcon from "../../shared/NCSvgIcon"
import ChangePasswordModal from "../ChangePasswordModal"
import { useState } from "react"
import { loggedInUser } from "../../libs/utils"
import { useNavigate } from "react-router-dom"
import NCModal from "../../shared/NCModal"
import {
  useDeactivateUser,
  useDeactivateTwoFactor
} from "../../services/mutations"
import { toast } from "react-toastify"
import { STORAGE } from "../../libs/storage"
import NCText from "../../shared/NCText"

const AccountSetting = () => {
  const [openPasswordModal, setOpenPasswordModal] = useState(false)
  const [showDeactivateModel, setShowDeactivateModel] = useState(false)
  const user = loggedInUser()
  const nav = useNavigate()
  const deactivateUserApi = useDeactivateUser()
  const deactivateTwoFactor = useDeactivateTwoFactor()

  const deactivateUser = id => {
    deactivateUserApi.mutate(id, {
      onSuccess: () => {
        toast.success("User has been deactivated successfully")
        nav("/logout")
      }
    })
  }

  const handleDeactivateTwoFactor = () => {
    deactivateTwoFactor.mutate(
      {},
      {
        onSuccess: () => {
          toast.success(
            "2 Factor Authentication has been deactivated successfully."
          )
          STORAGE.setItem("noahCares", {
            ...STORAGE.getItem("noahCares"),
            user: { ...user, is_two_factor_enabled: false }
          })
        }
      }
    )
  }
  return (
    <Box>
      <Box bgcolor={"#fff"} p={1} mb={1} borderRadius={"8px"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>Password</NCText>
          </Grid>
          <Grid item>
            <NCButton
              variant="contained"
              color="primary"
              onClick={() => setOpenPasswordModal(true)}
              endIcon={
                <NCSvgIcon component={OpenModalIcon} inheritViewBox={false} />
              }
            >
              Change Password
            </NCButton>
          </Grid>
        </Grid>
        <ChangePasswordModal
          open={openPasswordModal}
          onClose={() => setOpenPasswordModal(false)}
        />
      </Box>
      <Box bgcolor={"#fff"} p={1} mb={1} borderRadius={"8px"}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>Two factor authentication</NCText>
          </Grid>
          <Grid item>
            <NCButton
              variant="contained"
              color={user.is_two_factor_enabled ? "secondary" : "primary"}
              onClick={() =>
                user.is_two_factor_enabled
                  ? handleDeactivateTwoFactor()
                  : nav("/authsetup")
              }
            >
              {user.is_two_factor_enabled ? "Deactivate" : "Activate"}
            </NCButton>
          </Grid>
        </Grid>
      </Box>
      <Box
        p={1}
        mb={1}
        borderRadius={"8px"}
        bgcolor={"rgba(226, 33, 52, 0.1)"}
        border={"1px solid #EF4444"}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>Deactivate my account</NCText>
          </Grid>
          <Grid item>
            <NCButton
              variant="contained"
              color="secondary"
              onClick={() => setShowDeactivateModel(true)}
            >
              Deactivate
            </NCButton>
          </Grid>
        </Grid>
        <NCModal
          open={showDeactivateModel}
          title={"Deactivate"}
          text={"Are you sure you want to Deactivate User ?"}
          onClose={() => setShowDeactivateModel(false)}
          submitButton={
            <NCButton
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => deactivateUser(user.id)}
              style={{ width: "auto" }}
            >
              Confirm
            </NCButton>
          }
        />
      </Box>
    </Box>
  )
}

export default AccountSetting
