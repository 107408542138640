import { Box, Grid } from "@material-ui/core"
import NCSwitch from "../../shared/NCSwitch"
import NCLoader from "../../shared/NCLoader"
import { useGetNotificationSetting } from "../../services/queries"
import { useUpdateNotificationSetting } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import NCText from "../../shared/NCText"

const Notification = () => {
  const queryClient = useQueryClient()
  const { isLoading, data } = useGetNotificationSetting()
  const notificationSetting = data?.data?.data?.results?.[0] || {}
  const updateNotificationApi = useUpdateNotificationSetting()
  const updateNotification = input => {
    let requestObj = {
      id: notificationSetting.id,
      app_notification: notificationSetting.app_notification,
      push_notification: notificationSetting.push_notification,
      email_notification: notificationSetting.email_notification,
      ...input
    }
    updateNotificationApi.mutate(requestObj, {
      onSuccess: () => {
        toast.success("Notification Setting has been updated")
        queryClient.invalidateQueries(["getNotificationSetting"])
      }
    })
  }
  return (
    <Box>
      <Box bgcolor={"#fff"} p={1} mb={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>Email Notification</NCText>
          </Grid>
          <Grid item>
            <NCSwitch
              checked={notificationSetting?.email_notification || false}
              onClick={() =>
                updateNotification({
                  email_notification: !notificationSetting?.email_notification
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      {/* <Box bgcolor={"#fff"} p={1} mb={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>Push Notification</NCText>
          </Grid>
          <Grid item>
            <NCSwitch
              checked={notificationSetting?.push_notification || false}
              onClick={() =>
                updateNotification({
                  push_notification: !notificationSetting?.push_notification
                })
              }
            />
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor={"#fff"} p={1}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <NCText variant={"body2"}>App Notification</NCText>
          </Grid>
          <Grid item>
            <NCSwitch
              checked={notificationSetting?.app_notification || false}
              onClick={() =>
                updateNotification({
                  app_notification: !notificationSetting?.app_notification
                })
              }
            />
          </Grid>
        </Grid>
      </Box> */}
      <NCLoader open={isLoading || updateNotificationApi.isLoading} />
    </Box>
  )
}

export default Notification
