import { Box } from "@material-ui/core"
import { useState } from "react"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"

const NCAddress = props => {
  const [value, setValue] = useState()

  const getValue = data => {
    try {
      let parsedAddress = JSON.parse(data)
      return parsedAddress
    } catch (e) {
      return value
    }
  }

  return (
    <Box
      mt={"6px"}
      mb={"20px"}
      position={"relative"}
      className={props.error ? "error-address" : ""}
    >
      <GooglePlacesAutocomplete
        apiKey="AIzaSyAbXiODER5EIwsPZQINXIlh3aJPl5Ow_F4"
        selectProps={{
          value: getValue(props.value),
          onChange: val => {
            setValue(val)
            props.onChange(JSON.stringify(val))
          },
          onFocus: () => {
            setValue(null)
            props.onChange(null)
          },
          styles: {
            placeholder: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            input: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            option: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            }),
            singleValue: provided => ({
              ...provided,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize: "14px"
            })
          }
        }}
      />
      {props.error && (
        <span
          style={{
            position: "absolute",
            bottom: "-20px",
            color: "#f44336",
            margin: "0 14px",
            fontSize: "0.75rem",
            marginTop: "3px",
            textAlign: "left",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: "400",
            lineHeight: "1.66"
          }}
        >
          {props.error}
        </span>
      )}
    </Box>
  )
}

export default NCAddress
