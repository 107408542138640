import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core"
import { ClockIcon } from "../libs/icons"
import { format, isValid } from "date-fns"

const useStyles = makeStyles(() => ({
  input: {
    // padding: "0px 8px",
    fontSize: "14px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px"
    }
  },
  noFill: {
    color: "red"
  }
}))
const NCTimePicker = props => {
  const [value, setValue] = useState(null)
  const cls = useStyles()

  useEffect(() => {
    if (props.value) {
      setValue(
        isValid(props.value)
          ? new Date(props.value.replaceAll("-", "/"))
          : props.value
      )
    }
  }, [props.value])

  const handleChange = v => {
    setValue(v)
    if (isValid(v)) {
      let a = format(v, "yyyy-MM-dd hh:mm a")
      props.onChange(a)
    } else {
      props.onChange(v)
    }
  }

  return (
    <Grid container justifyContent="space-around">
      <KeyboardTimePicker
        {...props}
        id="time-picker"
        inputVariant="outlined"
        minDate={props.min ? props.min : undefined}
        maxDate={props.max ? props.max : undefined}
        InputProps={{
          className: cls.input
        }}
        format="hh:mm a"
        value={value}
        onChange={v => handleChange(v)}
        KeyboardButtonProps={{
          "aria-label": "change time"
        }}
        keyboardIcon={<ClockIcon />}
      />
    </Grid>
  )
}
export default NCTimePicker
