import React from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { Link, Box, IconButton } from "@material-ui/core"
import {
  AdminIcon,
  AidMonitorsIcon,
  AnalyticsIcon,
  // AppointmentIcon,
  ArrowIcon,
  ArrowSelectedIcon,
  // BellMenuIcon,
  ClientsIcon,
  DispatchersIcon,
  DriversIcon,
  FeedbackIcon,
  MapIcon,
  OverviewIcon,
  PhoneCallIcon,
  TrainingIcon,
  VehicleIcon
} from "../libs/icons"
import LogoIcon from "../assets/icons/menu_icons/logo.svg"
import LogoTextIcon from "../assets/icons/menu_icons/logotext.svg"
import UserImg from "../assets/icons/menu_icons/user.svg"
import NCSvgIcon from "../shared/NCSvgIcon"
import { loggedInUser } from "../libs/utils"
import { USER_TYPE } from "../libs/config"
import UserMenu from "./UserMenu"
import NCText from "../shared/NCText"
import { STORAGE } from "../libs/storage"
import withContext from "./DrawerConsumer"
import ChatIcon from "../assets/icons/chat.svg"
import AddFeedback from "../containers/feedback/Add"
import { useState } from "react"
import NCTooltip from "../shared/NCTooltip"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    height: "100vh",
    borderRadius: "0 20px 20px 0"
  },
  drawerOpen: {
    width: drawerWidth,
    background: "linear-gradient(174.61deg, #1EAAC4 0%, #10658E 104.65%)",
    // position: "relative",
    borderRadius: "0 20px 20px 0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    background: "linear-gradient(174.61deg , #1EAAC4 0%, #10658E 104.65%)",
    borderRadius: "0 20px 20px 0",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    }
  },
  menuList: {
    padding: "8px 20px 8px 20px",
    marginTop: "10px"
  },
  listItem: {
    color: "#FAFAFA",
    borderRadius: "20px",
    padding: "8px",
    "&:hover": {
      textDecoration: "none"
    },
    [theme.breakpoints.up("xl")]: {
      margin: "40px 0"
    }
  },
  leftArrow: {
    position: "absolute",
    background: "#fff",
    borderRadius: "20px 0 0 20px",
    right: 0,
    top: "25px",
    zIndex: 1,
    padding: "10px 10px 5px 10px"
  },
  rightArrow: {
    top: "25px",
    left: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      left: theme.spacing(9) + 1
    },
    zIndex: 1,
    background: theme.palette.primary.main,
    borderRadius: "0 20px 20px 0",
    position: "fixed",
    padding: "10px 10px 5px 10px"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1)
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    position: "relative"
    // height: `cal(100%- ${theme.spacing(3)}px)`,
    // overflowX: "auto"
  }
}))

const ACCESS_ROLES = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  SUB_ADMIN: 3,
  DISPATCHER: 4
}

const MENU_ITEMS = [
  {
    name: "Requests",
    icon: OverviewIcon,
    to: "/overview",
    role: [
      ACCESS_ROLES.SUPER_ADMIN,
      ACCESS_ROLES.ADMIN,
      ACCESS_ROLES.SUB_ADMIN,
      ACCESS_ROLES.DISPATCHER
    ]
  },
  {
    name: "Clients",
    icon: ClientsIcon,
    to: "/clients",
    role: [ACCESS_ROLES.SUPER_ADMIN, ACCESS_ROLES.ADMIN, ACCESS_ROLES.SUB_ADMIN, ACCESS_ROLES.DISPATCHER]
  },

  {
    name: "Admins",
    icon: AdminIcon,
    to: "/admins",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Subadmins (School)",
    icon: AdminIcon,
    to: "/subadmins",
    role: [ACCESS_ROLES.SUPER_ADMIN, ACCESS_ROLES.ADMIN]
  },
  {
    name: "Dispatchers",
    icon: DispatchersIcon,
    to: "/dispatchers",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Drivers",
    icon: DriversIcon,
    to: "/drivers",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Aid/Monitor",
    icon: AidMonitorsIcon,
    to: "/aidmonitor",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Map",
    icon: MapIcon,
    to: "/map",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Vehicles",
    icon: VehicleIcon,
    to: "/vehicles",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Training",
    icon: TrainingIcon,
    to: "/training",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Phone calls",
    icon: PhoneCallIcon,
    to: "/phonecalls",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Analytics",
    icon: AnalyticsIcon,
    to: "/analytics",
    role: [ACCESS_ROLES.SUPER_ADMIN]
  },
  {
    name: "Feedback",
    icon: FeedbackIcon,
    to: "/feedback",
    role: [ACCESS_ROLES.SUPER_ADMIN, ACCESS_ROLES.ADMIN, ACCESS_ROLES.SUB_ADMIN]
  },
  // {
  //   name: "Appointments",
  //   icon: AppointmentIcon,
  //   to: "/appointments",
  //   role: [ACCESS_ROLES.DISPATCHER]
  // },
  // {
  //   name: "Cancelled rides",
  //   icon: BellMenuIcon,
  //   to: "/notification",
  //   role: [ACCESS_ROLES.DISPATCHER]
  // }
]

const MiniDrawer = props => {
  const [chatWindow, showChatWindow] = useState(false)
  const classes = useStyles()
  const open = props.drawer
  const user = loggedInUser()

  const handleDrawerClose = () => {
    // setOpen(!open)
    props.setDrawer(!props.drawer)
    STORAGE.setItem("noahCares", {
      ...STORAGE.getItem("noahCares"),
      drawerState: !props.drawer
    })
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        {open ? (
          <NCSvgIcon
            onClick={handleDrawerClose}
            component={ArrowSelectedIcon}
            className={classes.leftArrow}
            inheritViewBox={false}
          />
        ) : (
          ""
        )}
        {open ? (
          <Box pt={3} pl={2}>
            <img src={LogoTextIcon} width={"70%"} />
          </Box>
        ) : (
          <Box pt={3} pl={2}>
            <img src={LogoIcon} width={"90%"} />
          </Box>
        )}
        <List className={classes.menuList}>
          {MENU_ITEMS.filter(item => item.role.includes(user.user_type)).map(
            (item, index) => (
              <ListItem
                button
                key={index}
                className={classes.listItem}
                style={open ? { width: "auto" } : { width: "fit-content" }}
                component={Link}
                href={item.to}
                selected={location.pathname.includes(item.to)}
              >
                <Box fontSize={"16px"}>
                  <NCSvgIcon component={item.icon} inheritViewBox />
                </Box>
                <Box display={open ? "block" : "none"} ml={"10px"}>
                  <NCText variant={"body2"}>{item.name}</NCText>
                </Box>
              </ListItem>
            )
          )}
        </List>
        <Box position={"fixed"} bottom={"15px"} width={"inherit"}>
          {user.user_type === 2 || user.user_type === 3 ? (
            <Box bgcolor={"rgba(0, 0, 0, 0.04)"} py={"10px"}>
              {open ? (
                <Box pr={"26px"} pl={"26px"} mb={"10px"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => showChatWindow(!chatWindow)}
                    className="cursor-pointer"
                  >
                    <img src={ChatIcon} alt="user" width={"25px"} />
                    <Box color={"#fff"} ml={"10px"}>
                      <NCText variant={"body2"}>{"Send Feedback"}</NCText>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box px={"10px"} mb={"10px"}>
                  <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={() => showChatWindow(!chatWindow)}
                  >
                    <img src={ChatIcon} width={"25px"} />
                  </IconButton>
                </Box>
              )}
            </Box>
          ) : (
            ""
          )}
          {open ? (
            <Box pr={"26px"} pl={"26px"}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <img src={UserImg} alt="user" width={"25px"} />
                  <Box color={"#fff"} ml={"10px"}>
                    <NCTooltip title={user.name}>
                      <div>
                        <NCText
                          variant={"body2"}
                          className={"overflow-ellipsis"}
                          style={{ width: "80%", paddingRight: "0" }}
                        >
                          {user.name || ""}
                        </NCText>
                      </div>
                    </NCTooltip>
                    <NCText variant={"caption"}>
                      {USER_TYPE[user.user_type]}
                    </NCText>
                  </Box>
                </Box>
                <UserMenu />
              </Box>
            </Box>
          ) : (
            <Box px={"10px"}>
              <UserMenu
                icon={<img src={UserImg} alt="user" width={"25px"} />}
              />
            </Box>
          )}
        </Box>
      </Drawer>
      <main className={classes.content}>
        {open ? (
          ""
        ) : (
          <NCSvgIcon
            onClick={handleDrawerClose}
            component={ArrowIcon}
            className={classes.rightArrow}
            inheritViewBox={false}
          />
        )}
        {props.children}
        {chatWindow ? <AddFeedback showChatWindow={showChatWindow} /> : null}
      </main>
    </div>
  )
}

export default withContext(MiniDrawer)
