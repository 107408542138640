import { SampleContext } from "../App"

const withContext = Child => props =>
  (
    <SampleContext.Consumer>
      {context => <Child {...props} {...context} />}
    </SampleContext.Consumer>
  )

export default withContext
