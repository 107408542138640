import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import { useGetFeedbackList } from "../../services/queries"
import { useState } from "react"
import NCTable from "../../shared/table/NCTable"
import FeedbackCard from "../../components/feedback/FeedbackCard"
import { TABLE_CONFIG } from "../../libs/config"
import { loggedInUser } from "../../libs/utils"
import NotificationAlert from "../../components/notification/NotificationAlert"

const FeedbackList = () => {
  const [selectedTab, setSelectedTab] = useState(null)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const user = loggedInUser()
  const getUserType = () => {
    return selectedTab
    // switch (selectedTab) {
    //   case 0: {
    //     return null
    //   }
    //   case 1: {
    //     return 2
    //   }
    //   case 2: {
    //     return 3
    //   }
    //   case 3: {
    //     return 6
    //   }
    // }
  }
  const { isLoading, data } = useGetFeedbackList(page, null, pageSize, {
    created_user_type: getUserType()
  })
  const { next, previous, total_pages, results } = data?.data?.data || {}
  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const columns = [
    {
      Header: "",
      id: "feedback",
      disableSortBy: true,
      accessor: cell => cell,
      Cell: ({ row }) => (
        <FeedbackCard detail={row?.values?.feedback} index={row.index} />
      )
    }
  ]
  const ACCESS_ROLES = {
    SUPER_ADMIN: 1,
    ADMIN: 2,
    SUB_ADMIN: 3
  }
  const Tabs = [
    {
      name: "All Feedback",
      role: [
        ACCESS_ROLES.SUPER_ADMIN,
        ACCESS_ROLES.ADMIN,
        ACCESS_ROLES.SUB_ADMIN
      ],
      user_type: null
    },
    {
      name: "Admin",
      role: [ACCESS_ROLES.SUPER_ADMIN],
      user_type: 2
    },
    {
      name: "Subadmin (School)",
      role: [ACCESS_ROLES.SUPER_ADMIN, ACCESS_ROLES.ADMIN],
      user_type: 3
    },
    {
      name: "Driver",
      role: [ACCESS_ROLES.SUPER_ADMIN],
      user_type: 6
    }
  ]
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box pb={"60px"}>
          <Grid container justifyContent="space-between">
            <Grid item xs={6}>
              <NCText variant={"h4"}>Feedbacks</NCText>
            </Grid>
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                <NotificationAlert />
              </Box>
            </Grid>
            <Grid item xs={12} className="feedback-container">
              <Box>
                <Box
                  display={"flex"}
                  gridGap={"10px"}
                  pt={"20px"}
                  borderBottom={"1px solid #E5E7EB"}
                >
                  {Tabs.filter(tab => tab.role.includes(user.user_type)).map(
                    tab => (
                      <Box
                        p={"10px"}
                        borderBottom={
                          selectedTab === tab.user_type
                            ? "1px solid #1EAAC4"
                            : "1px solid transparent"
                        }
                        onClick={() => setSelectedTab(tab.user_type)}
                        className={"cursor-pointer"}
                      >
                        <NCText
                          className="fw-bold"
                          color={
                            selectedTab === tab.user_type
                              ? "primary"
                              : "textSecondary"
                          }
                        >
                          {tab.name}
                        </NCText>
                      </Box>
                    )
                  )}
                </Box>
                <NCTable
                  columns={columns}
                  data={results || []}
                  page={page}
                  onNext={onNext}
                  onPrev={onPrev}
                  pageCount={total_pages}
                  gotoPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  isLoading={isLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  )
}

export default FeedbackList
