export const API_BASE_PATH = "https://noah-cares-38566-staging.botics.co"
export const API_PATH = {
  LOGIN: "/api/v1/login/",
  LOGOUT: "/rest-auth/logout/",
  RESET_PASSWORD: "/rest-auth/password/reset/",
  NEW_PASSWORD: "/rest-auth/password/reset/confirm/",
  CHANGE_PASSWORD: "/rest-auth/password/change/",
  PRIVACY_POLICY: "/modules/privacy-policy/",
  TERMS_AND_CONDITIONS: "/modules/terms-and-conditions/",
  CLIENT_LIST: "/clients/api/v1/",
  AUTHENTICATION:
    "/modules/two-factor-authentication/user-two-factor/register/",
  AUTHCODE_VERIFICATION:
    "/modules/two-factor-authentication/user-two-factor/2/verify/",
  ADD_ADMIN: "/admins/api/v1/",
  CLIENT_REQUEST_LIST: "/client-requests/api/v1/",
  ADMIN_LIST: "/admins/api/v1/",
  ADMIN_EMPLOYEE_LIST: "/admins/api/v1/employees/",
  SUB_ADMIN_LIST: "/subadmins/api/v1/",
  SUB_EMPLOYEE_LIST: "/subadmins/api/v1/sub-employees/",
  ADD_SUB_ADMIN_EMPLOYEE: "/subadmins/api/v1/sub-employees/",
  REPRESENTATIVE_LIST: "/admins/api/v1/company-representatives/",
  ADD_EMPLOYEE: "/admins/api/v1/employees/",
  EDIT_REPRESENTATIVE: "/admins/api/v1/company-representatives/",
  SEND_OTP: "/modules/two-factor-authentication/two-factor/",
  VERIFY_OTP: "/modules/two-factor-authentication/two-factor/",
  USER_TIPS: "/client-requests/api/v1/user-tips/",
  VEHICLE_REQUIREMENTS: "/client-requests/api/v1/vehicle-requirements/",
  SERVICE_DETAILS: "/client-requests/api/v1/service-details/",
  OTHER_REQUIREMENTS: "/client-requests/api/v1/other-requirements/",
  CLIENT_REQUESTS: "/client-requests/api/v1/",
  GET_FEEDBACK_LIST: "/feedbacks/api/v1/",
  GET_FEEDBACK_REPLIES: "/feedbacks/api/v1/reply/",
  FEEDBACK_ADMIN_REPLY: feedbackId =>
    `/feedbacks/api/v1/${feedbackId}/feedback-reply/`,
  USER_PROFILE: "/api/v1/profile/",
  GET_GUARDIAN_LIST: "/clients/api/v1/guardians/",
  EMERGENCY_CONTACT: "/clients/api/v1/emergency-contacts/",
  DISPATCHER_LIST: "/dispatchers/api/v1/",
  ADD_DISPATCHER: "/dispatchers/api/v1/",
  DRIVER: "/drivers/api/v1/",
  AID_MONITOR: "/aid_monitors/api/v1/",
  ADD_FEEDBACK: "/feedbacks/api/v1/",
  VERIFY_AUTH_SETUP: "/modules/two-factor-authentication/user-two-factor/",
  TWO_FACTOR_DEACTIVATE:
    "/modules/two-factor-authentication/user-two-factor/deregister/",
  NOTIFICATION_SETTING: "/api/v1/notification_setting/",
  NOTIFICATION: "/modules/firebase-push-notifications/notification/",
  NOTIFICATION_SUMMARY:
    "/modules/firebase-push-notifications/notification/summary/",
  READ_NOTIFICATION: "/modules/firebase-push-notifications/user-notification/",
  MARK_ALL_NOTIFICATION_AS_READ:
    "/modules/firebase-push-notifications/user-notification/mark-all-as-read/",
  CLIENT_AUDIT_LOG: "/clients/api/v1/history/",
  REQUEST_AUDIT_LOG: "/client-requests/api/v1/history/",
  ADMIN_AUDIT_LOG: "/admins/api/v1/history/",
  SUBADMIN_AUDIT_LOG: "/subadmins/api/v1/history/",
  DRIVER_AUDIT_LOG: "/drivers/api/v1/history/",
  DISPATCHER_AUDIT_LOG: "/dispatchers/api/v1/history/",
  AID_MONITOR_AUDIT_LOG: "/aid_monitors/api/v1/history/",
  CLIENT_REQUEST_ACTION_STATUS: "/client-requests/api/v1/",
  DRIVER_APPOINTMENT: "/drivers/api/v1/web-appointment/",
  DRIVER_AVAILABILITY: "/drivers/api/v1/web-availability/",
  ADD_DRIVER_AVAILABILITY: "/drivers/api/v1/availability/",
  DRIVER_LIST: "/dispatchers/api/v1/driver-list/",
  TRIPS: "/trips/api/v1/",
  VEHICLES: "/drivers/api/v1/vehicles/",
  ASSIGN_DRIVER: tripId => `/trips/api/v1/${tripId}/assign-driver/`,
  APPROVE_CLIENT_REQUEST: clientRequestId =>
    `/client-requests/api/v1/${clientRequestId}/default-driver/`,
  PUT_ON_HOLD: clientId => `/clients/api/v1/${clientId}/put-on-hold/`,
  RELEASE_HOLD: clientId => `/clients/api/v1/${clientId}/release-hold/`
}

export const TABLE_CONFIG = {
  PAGE_SIZE: 10
}
export const SEARCH_CONFIG = {
  TIME_IN_MILLI: 1000
}
export const GENDER = {
  M: "Male",
  F: "Female",
  O: "Other"
}

export const WORK_WEEK = {
  1: "Only Business Days",
  2: "Business Days Plus Saturday",
  3: "Whole Week",
  4: "No Frequency"
}

export const REQUEST_STATUS = {
  1: "Pending",
  2: "Approved",
  3: "Declined",
  4: "On Hold"
}
export const ACTIVITY_STATUS = {
  A: "Active",
  I: "Inactive",
  O: "On Hold"
}
export const DOCUMENT_VERIFICATION_STATUS = {
  PENDING: "Pending",
  VERIFIED: "Verified",
  REJECTED: "Rejected",
  EXPIRED: "Expired"
}

export const USER_TYPE = {
  1: "Super Admin",
  2: "Admin",
  3: "Sub Admin",
  4: "Dispatcher",
  5: "Aid Monitor",
  6: "Driver",
  7: "Guardian",
  8: "Client"
}
export const FEEDBACK_TYPE = {
  1: "Incident Report",
  2: "Late Report",
  3: "Billing Issue",
  4: "Missing Equipment"
}
// @unique
// class ClientStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'
//     ON_HOLD = 'O'

// @unique
// class DriverStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class RecordStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class RideRequestStatus(BaseEnum):
//     PENDING = 1
//     APPROVED = 2
//     DECLINED = 3

// @unique
// class DispatcherStatus(BaseEnum):
//     ACTIVE = 'A'
//     INACTIVE = 'I'

// @unique
// class DocumentVerificationStatus(BaseEnum):
//     PENDING = 'PENDING'
//     VERIFIED = 'VERIFIED'

// @unique
// class DriverVerificationStatus(BaseEnum):
//     PENDING = 1
//     APPROVED = 2
//     DECLINED = 3

export const UI_PATH = {
  CLIENTS: "/clients"
}
