import React, { useState } from "react"
import { KeyboardDatePicker } from "@material-ui/pickers"
import { makeStyles } from "@material-ui/core"
import { CalendarIcon } from "../libs/icons"
import { format, isValid } from "date-fns"
import { useEffect } from "react"

const useStyles = makeStyles(() => ({
  input: {
    // padding: "0px 8px",
    fontSize: "14px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px"
    }
  },
  noFill: {
    color: "red"
  }
}))
const NCDatePicker = props => {
  const [value, setValue] = useState(null)
  const cls = useStyles()

  useEffect(() => {
    if (props.value) {
      if (props.dateTime) {
        setValue(props.value)
      } else {
        let v = props.value.replaceAll("-", "/")
        if (isValid(props.value)) {
          v = new Date(v)
        }
        setValue(v)
        // setValue(
        //   isValid(props.value)
        //     ? new Date(props.value.replaceAll("-", "/"))
        //     : props.value
        // )
      }
    }
  }, [props.value])

  const handleChange = v => {
    setValue(v)
    if (isValid(v)) {
      let a = props.dateTime
        ? new Date(v).toISOString()
        : format(v, "yyyy-MM-dd")
      props.onChange(a)
    } else {
      props.onChange(v)
    }
  }
  return (
    <>
      <KeyboardDatePicker
        {...props}
        id="date-picker-inline"
        inputVariant="outlined"
        minDate={props.min ? props.min : undefined}
        maxDate={props.max ? props.max : undefined}
        InputProps={{
          className: cls.input
        }}
        format="MM/dd/yyyy"
        value={value}
        onChange={v => handleChange(v)}
        KeyboardButtonProps={{
          "aria-label": "change date"
        }}
        keyboardIcon={<CalendarIcon />}
        cancelLabel={false}
        okLabel={false}
        autoOk={true}
      />
    </>
  )
}
export default NCDatePicker
