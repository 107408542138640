import { Grid } from "@material-ui/core"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { useUpdateSubAdmin } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import FormField from "../FormField"
import NCButton from "../../shared/NCButton"
import { useQueryClient } from "react-query"
import NCLoader from "../../shared/NCLoader"
import NCAddress from "../../shared/NCAddress"

const EditSubAdminModal = ({
  subAdminId,
  modalState = false,
  handleClose,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const updateAdminApi = useUpdateSubAdmin()
  const formik = useFormik({
    initialValues: {
      name: data.name || "",
      address: data.address || ""
    },
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    onSubmit(values) {
      updateAdminApi.mutate(
        { ...values, id: subAdminId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getSubAdminDetail"])
            queryClient.invalidateQueries(["getSubAdminAuditLog"])
            toast.success("SubAdmin (School) details edited successfully")
            handleClose()
          }
        }
      )
    }
  })
  const companyModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormField
              label={"Name"}
              name={"name"}
              required={true}
              error={formik?.errors?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              as={NCAddress}
              label={"Address"}
              name={"address"}
              onChange={e => formik.setFieldValue("address", e)}
              error={formik?.errors?.address}
            />
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )
  return (
    <>
      <NCModal
        open={modalState}
        title={"Edit Subadmin (School)"}
        text={companyModal}
        onClose={() => handleClose()}
        submitButton={
          <NCButton
            variant="contained"
            color="primary"
            disableElevation
            onClick={formik.handleSubmit}
            style={{ width: "auto" }}
          >
            Update
          </NCButton>
        }
      />
      {updateAdminApi.isLoading && <NCLoader open={true} />}
    </>
  )
}

export default EditSubAdminModal
