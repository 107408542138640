import { useState } from "react"
import { useSearchParams, Link } from "react-router-dom"
import { Grid, makeStyles, Paper } from "@material-ui/core"
import NCContent from "../shared/NCContent"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import successLogo from "../assets/images/successLogo.svg"
import { ErrorMessage, Form, Field, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useNewPassword } from "../services/mutations"
import NCLoader from "../shared/NCLoader"
import NCPasswordInput from "../shared/NCPasswordInput"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
    minWidth: "350px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "5px"
  },
  text: {
    fontSize: "14px",
    paddingTop: "5px",
    paddingBottom: "20px"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  reset: {
    textDecoration: "none",
    display: "flex",
    cursor: "auto"
  }
}))

const NewPassword = () => {
  const cls = useStyles()
  const changePasswordRequest = useNewPassword()
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false)
  const [queryParameters] = useSearchParams()
  const uid = queryParameters.get("uid")
  const token = queryParameters.get("token")
  const formik = useFormik({
    initialValues: {
      new_password1: "",
      new_password2: ""
    },
    validationSchema: Yup.object().shape({
      new_password1: Yup.string().required("Password is required"),
      new_password2: Yup.string()
        .required("Password is required")
        .oneOf([Yup.ref("new_password1"), null], "Passwords must match")
    }),
    onSubmit(values) {
      changePasswordRequest.mutate(
        { uid, token, ...values },
        {
          onSuccess: () => {
            setIsPasswordUpdated(true)
          }
        }
      )
    }
  })
  const passwordUpdateContent = (
    <Grid item xs={8}>
      <Paper className={cls.paper}>
        <img src={successLogo} style={{ width: "25px" }} />
        <NCTitle className={cls.title}>Password Reset</NCTitle>
        <NCText className={cls.text}>Password changed successfully.</NCText>
        <Link className={cls.reset} to="/login">
          <NCContent className="cursor-pointer" style={{ color: "#1EAAC4" }}>
            Login Now &nbsp;&gt;
          </NCContent>
        </Link>
      </Paper>
    </Grid>
  )

  const newPasswordContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>New Password</NCTitle>
        <NCText className={cls.text}>Please enter your new password</NCText>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <NCContent error={!!formik?.errors?.new_password1}>
              New Password *
            </NCContent>
            <Field
              as={NCPasswordInput}
              className={cls.input}
              placeholder="Enter Password"
              name="new_password1"
              type="password"
              error={!!formik?.errors?.new_password1}
              helperText={
                <ErrorMessage
                  className={cls.errorMessage}
                  component={NCText}
                  name="new_password1"
                />
              }
            />
            <NCContent error={!!formik?.errors?.new_password2}>
              Confirm Password *
            </NCContent>
            <Field
              as={NCPasswordInput}
              className={cls.input}
              placeholder="Enter Password"
              name="new_password2"
              type="password"
              error={!!formik?.errors?.new_password2}
              helperText={
                <ErrorMessage
                  className={cls.errorMessage}
                  component={NCText}
                  name="new_password2"
                />
              }
            />
            <NCButton type="submit" variant="contained" color="primary">
              Change Password
            </NCButton>
            {changePasswordRequest.isLoading && <NCLoader open={true} />}
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )
  return isPasswordUpdated ? (
    <AuthComponent>{passwordUpdateContent}</AuthComponent>
  ) : (
    <AuthComponent>{newPasswordContent}</AuthComponent>
  )
}

export default NewPassword
