import { Box, CircularProgress, Grid, Icon } from "@material-ui/core"
import NCText from "../../shared/NCText"
import { FeedbackUserIcon } from "../../libs/icons"
import { FEEDBACK_TYPE, USER_TYPE } from "../../libs/config"
import { formatDate } from "../../libs/utils"
import { useGetFeedbackDetail } from "../../services/queries"

const FeedbackPopup = ({ detail, edit }) => {
  const { isLoading, data } = useGetFeedbackDetail(detail.id)
  const replies = data?.data?.data?.results || []

  const UserReply = ({ reply }) => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"start"}
            padding={"0 10px"}
            mb={"10px"}
          >
            <Box display={"flex"} gridGap={"10px"} alignItems={"center"}>
              <Box width={"30px"}>
                {reply?.user_info?.avatar ? (
                  <img
                    src={reply?.user_info?.avatar}
                    alt="usr-img"
                    width={"100%"}
                  />
                ) : (
                  <Icon>
                    <FeedbackUserIcon width={"100%"} />
                  </Icon>
                )}
              </Box>
              <Box>
                <NCText variant="subtitle2">
                  <Box
                    fontWeight={"bold"}
                    style={{ "text-transform": "capitalize" }}
                  >
                    {reply?.user_info?.name}
                  </Box>
                </NCText>
                <NCText variant="body" color={"textSecondary"}>
                  {reply.reply}
                </NCText>
              </Box>
            </Box>
            <Box bgcolor={"#EFFDFF"} p={"5px"} borderRadius={"8px"}>
              <NCText variant="body" color={"textSecondary"}>
                {formatDate(reply.created)}
                {" / "}
                {new Date(reply.created).toLocaleTimeString("en-US")}
              </NCText>
            </Box>
          </Box>
        </Grid>
      </Grid>
    )
  }
  return isLoading ? (
    <CircularProgress />
  ) : (
    <Box maxHeight={"300px"} overflow={"scroll"}>
      <Box
        border={"1px solid #E3E3E3"}
        borderRadius={"8px"}
        mb={1}
        bgcolor={"#fff"}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              bgcolor={"#F2F2F2"}
              p={"2px 16px"}
              // borderTopRightRadius="8px"
              style={{
                borderTopRightRadius: "8px",
                borderTopLeftRadius: "8px"
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} gridGap={"10px"} alignItems={"center"}>
                  <Box width={"40px"} paddingTop={"8px"}>
                    {detail?.user_info?.avatar ? (
                      <img
                        src={detail?.user_info?.avatar}
                        alt="usr-img"
                        width={"100%"}
                      />
                    ) : (
                      <Icon>
                        <FeedbackUserIcon width={"100%"} />
                      </Icon>
                    )}
                  </Box>
                  <Box>
                    <NCText variant="subtitle2">
                      <Box
                        fontWeight={"bold"}
                        style={{ "text-transform": "capitalize" }}
                      >
                        {detail?.user_info?.name}
                      </Box>
                    </NCText>

                    <Box>
                      <NCText variant="body">
                        {USER_TYPE[detail?.user_info?.user_type]}
                      </NCText>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    bgcolor={"#E5E7EB"}
                    color={"#2E3B36"}
                    p={"5px 10px"}
                    borderRadius={"10px"}
                  >
                    <NCText variant="body">
                      {`Client Name: ${detail?.on_behalf_of_client_info?.name}`}
                    </NCText>
                  </Box>
                  <Box
                    mt={0.5}
                    bgcolor={"#EFFDFF"}
                    p={0.5}
                    paddingLeft={1.5}
                    borderRadius={"8px"}
                  >
                    <NCText variant="body" color={"textSecondary"}>
                      {formatDate(detail.created)}
                      {" / "}
                      {new Date(detail.created).toLocaleTimeString("en-US")}
                    </NCText>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box p={"8px 16px 8px 16px"}>
              <Grid container>
                <Grid item xs>
                  <Box></Box>
                  <Box display={"flex"} gridGap={"2px"} alignItems={"center"}>
                    <NCText variant={"h6"}>
                      <Box fontWeight={"bold"}>{detail.title}</Box>
                    </NCText>
                    <NCText variant="subtitle1" color={"textSecondary"}>
                      ({FEEDBACK_TYPE[detail.type]})
                    </NCText>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={"0 16px 16px"}>
              <NCText variant="body" color={"textSecondary"}>
                {detail.message}
              </NCText>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {replies
        .sort(function (a, b) {
          return new Date(a.created) - new Date(b.created)
        })
        .map(reply => {
          return <UserReply reply={reply} />
        })}

      {edit ? (
        edit
      ) : (
        <>
          <Grid item xs={12}>
            <NCText variant="body" color={"textSecondary"}>
              Reply
            </NCText>
          </Grid>
          <Grid item xs={12}>
            <NCText variant="body" color={"textSecondary"}>
              {detail?.admin_reply}
            </NCText>
          </Grid>
        </>
      )}
    </Box>
  )
  // <Grid container>
  //   <Grid item container xs={12}>
  //     <Grid item xs>
  //       <Box display={"flex"} gridGap={"10px"}>
  //         <Box width={"40px"}>
  //           {detail?.user_info?.avatar ? (
  //             <img
  //               src={detail?.user_info?.avatar}
  //               alt="usr-img"
  //               width={"100%"}
  //             />
  //           ) : (
  //             <Icon>
  //               <FeedbackUserIcon width={"100%"} />
  //             </Icon>
  //           )}
  //         </Box>
  //         <Box>
  //           <NCText variant="subtitle1">
  //             <Box fontWeight={"bold"}>{detail?.user_info?.name}</Box>
  //           </NCText>

  //           <Box>
  //             <NCText variant="body">
  //               {USER_TYPE[detail?.user_info?.user_type]}
  //             </NCText>
  //           </Box>
  //         </Box>
  //       </Box>
  //     </Grid>
  //     <Grid item>
  //       <Box bgcolor={"#EFFDFF"} p={"10px"} borderRadius={"8px"}>
  //         <NCText variant="body" color={"textSecondary"}>
  //           {formatDate(detail.created)}
  //           {" / "}
  //           {new Date(detail.created).toLocaleTimeString("en-US")}
  //         </NCText>
  //       </Box>
  //     </Grid>
  //     <Grid item xs={12}>
  //       <Box display={"flex"} gridGap={"5px"} alignItems={"center"}>
  //         <NCText variant={"subtitle1"}>
  //           <Box fontWeight={"bold"}>{detail.title}</Box>
  //         </NCText>
  //         <NCText variant="subtitle1" color={"textSecondary"}>
  //           ({FEEDBACK_TYPE[detail.type]})
  //         </NCText>
  //       </Box>
  //     </Grid>
  //     <Grid item xs={12}>
  //       <Box margin={"0  0 24px"}>
  //         <NCText variant="body" color={"textSecondary"}>
  //           {detail.message}
  //         </NCText>
  //       </Box>
  //     </Grid>
  //     {replies.sort(function (a, b) { return new Date(a.created) - new Date(b.created) }).map(reply => {
  //       return <UserReply reply={reply} />
  //     })}

  //     {edit ? (
  //       edit
  //     ) : (
  //       <>
  //         <Grid item xs={12}>
  //           <NCText variant="body" color={"textSecondary"}>
  //             Reply
  //           </NCText>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <NCText variant="body" color={"textSecondary"}>
  //             {detail?.admin_reply}
  //           </NCText>
  //         </Grid>
  //       </>
  //     )}
  //   </Grid>
  //   <NCLoader open={isLoading} />
  // </Grid>
}

export default FeedbackPopup
