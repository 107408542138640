import { Button, Grid, IconButton, makeStyles } from "@material-ui/core"
import NCTable from "../../shared/table/NCTable"
import { DeleteIcon, EditIcon, ReviveIcon } from "../../libs/icons"
import { useGetGuardianList } from "../../services/queries"
import { ACTIVITY_STATUS, SEARCH_CONFIG, TABLE_CONFIG } from "../../libs/config"
import { useState } from "react"
import AddGuardianModal from "./AddGuardianModal"
import FormSection from "../FormSection"
import Filter from "../../shared/table/Filter"
import NCTableStatus from "../../shared/table/NCTableStatus"
import useDebounce from "../../libs/hooks/Debounce"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import {
  useActivateGuardian,
  useDeleteGuardian
} from "../../services/mutations"
import { loggedInUser } from "../../libs/utils"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px",
    width: "180px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    fintWeight: "600"
  }
}))
const GuardianList = ({ clientId }) => {
  const cls = useStyles()
  const user = loggedInUser()
  const [page, setPage] = useState(1)
  const [query] = useState("")
  const [guardianModal, setGuardianModal] = useState(false)
  const [selectedGuardian, setSelectedGuardian] = useState()
  const [pageSize, setPageSize] = useState(TABLE_CONFIG.PAGE_SIZE)
  const [ordering, setOrdering] = useState("id")
  const [statusFilter, setStatusFilter] = useState([])
  const [showDeleteModel, setShowDeleteModel] = useState(false)
  const debouncedSearchTerm = useDebounce(query, SEARCH_CONFIG.TIME_IN_MILLI)
  const queryClient = useQueryClient()
  const { isLoading, data } = useGetGuardianList(
    page,
    debouncedSearchTerm,
    pageSize,
    {
      ordering,
      status:
        statusFilter && statusFilter.length
          ? statusFilter[0].value.toString()
          : null,
      client: clientId
    }
  )
  const { next, previous, total_pages, results } = data?.data?.data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }
  const deleteGuardianApi = useDeleteGuardian()
  const activateGuardianApi = useActivateGuardian()
  const deleteGuardian = guardianId => {
    deleteGuardianApi.mutate(guardianId, {
      onSuccess: () => {
        toast.success("Guardian has been deleted successfully")
        queryClient.invalidateQueries(["getGuardianList"])
        queryClient.invalidateQueries(["getClientAuditLog"])
        setShowDeleteModel(false)
      }
    })
  }
  const handleDeleteClick = cell => {
    if (cell.status === "I") {
      //reactivate
      activateGuardianApi.mutate(cell.id, {
        onSuccess: () => {
          toast.success("Guardian has been activated successfullty")
          queryClient.refetchQueries(["getGuardianList"])
          queryClient.invalidateQueries(["getClientAuditLog"])
          setShowDeleteModel(false)
        }
      })
    } else {
      setSelectedGuardian(cell)
      setShowDeleteModel(true)
    }
  }
  const getActionColumn = cell => {
    return (
      <div className="action-icons">
        <IconButton
          onClick={() => {
            setSelectedGuardian(cell)
            setGuardianModal(true)
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteClick(cell)}>
          {cell.status === "I" ? <ReviveIcon /> : <DeleteIcon />}
        </IconButton>
      </div>
    )
  }
  const SelectColumnFilter = ({ column: { filterValue, setFilter } }) => {
    return (
      <Filter
        options={[
          { key: "A", name: "Active" },
          { key: "I", name: "Inactive" }
        ]}
        filters={filterValue}
        setFilters={setFilter}
      />
    )
  }
  let columns = [
    {
      Header: "Guardian Name",
      accessor: "name",
      disableSortBy: true
    },
    {
      Header: "Guardian Email",
      accessor: "email",
      disableSortBy: true
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      disableSortBy: true
    },
    {
      Header: "Status",
      id: "status",
      accessor: cell => <NCTableStatus status={ACTIVITY_STATUS[cell.status]} />,
      defaultCanFilter: true,
      Filter: SelectColumnFilter,
      disableSortBy: true
    }
  ]
  if (user.user_type === 1 || user.user_type === 2) {
    columns.push({
      Header: "",
      id: "action",
      disableSortBy: true,
      accessor: cell => getActionColumn(cell)
    })
  }
  const guardianTitle = (
    <Grid item className={cls.titleSection}>
      Guardian Details
      {user.user_type === 1 || user.user_type === 2 ? <Button
        onClick={() => {
          setGuardianModal(true)
          setSelectedGuardian()
        }}
        className={cls.button}
        variant="contained"
        color="primary"
      >
        Add Guardian
      </Button> : null}
    </Grid>
  )
  return (
    <FormSection title={guardianTitle}>
      <NCTable
        columns={columns}
        data={results || []}
        page={page}
        onNext={onNext}
        onPrev={onPrev}
        pageCount={total_pages}
        gotoPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        sorting={ordering}
        setSorting={setOrdering}
        filter={statusFilter}
        setFilter={setStatusFilter}
        isLoading={isLoading}
        inlineFooter={true}
      />
      {
        <AddGuardianModal
          isEdit={selectedGuardian ? true : false}
          clientId={clientId}
          modalState={guardianModal}
          handleClose={() => {
            setGuardianModal(false)
          }}
          data={selectedGuardian}
        />
      }
      <NCModal
        open={showDeleteModel}
        title={"Delete"}
        text={"Are you sure you want to Delete Guardian ?"}
        onClose={() => setShowDeleteModel(false)}
        submitButton={
          <NCButton
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => deleteGuardian(selectedGuardian.id)}
            style={{ width: "auto" }}
          >
            Yes
          </NCButton>
        }
      />
    </FormSection>
  )
}

export default GuardianList
