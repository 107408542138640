import { FormLabel, makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(() => ({
  root: {
    color: "#9CA3AF",
    fontSize: "14px",
    fontWeight: 600
  }
}))

const NCContent = props => {
  const cls = useStyles()

  return (
    <FormLabel
      {...props}
      error={props.error || false}
      component="label"
      className={clsx(cls.root, props.className)}
    />
  )
}

export default NCContent
