import { Box, InputAdornment, makeStyles, TextField } from "@material-ui/core"
import NCSvgIcon from "./NCSvgIcon"

const useStyles = makeStyles(() => ({
  input: {
    // padding: "0px 8px",
    fontSize: "14px",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D5DB"
      },
      "&:hover fieldset": {
        borderColor: "#1EAAC4"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1EAAC4",
        borderWidth: "1px"
      }
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px"
    }
  },
  noFill: {
    color: "red"
  }
}))

const NCInput = props => {
  const cls = useStyles()

  return (
    <TextField
      {...props}
      error={props.error || false}
      inputProps={{
        min: props.min,
        max: props.max
      }}
      InputProps={{
        className: cls.input,
        readOnly: props.readOnly ? props.readOnly : false,
        startAdornment: props.startIcon ? (
          <InputAdornment position="start">
            <Box fontSize={"24px"}>
              <NCSvgIcon
                component={props.startIcon}
                inheritViewBox
                className={cls.noFill}
              />
            </Box>
          </InputAdornment>
        ) : null
      }}
      SelectProps={{
        native: true
      }}
      className={props.className}
      variant="outlined"
    />
  )
}

export default NCInput
