import { useState } from "react"
import { Grid, makeStyles, Paper } from "@material-ui/core"
import NCTitle from "../shared/NCTitle"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import PhoneAuthentication from "./PhoneAuthentication"
import GoogleAuthentication from "./GoogleAuthentication"
import EmailAuthentication from "./EmailAuthentication"
import { useAuthentication } from "../services/mutations"
import { Form, useFormik, FormikProvider } from "formik"
import NCLoader from "../shared/NCLoader"
import NCRadio from "../shared/NCRadio"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    width: "100%",
    padding: "24px 30px",
    // marginBottom: "120px",
    borderRadius: "20px",
    minWidth: "350px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "25px"
  },
  waitText: {
    fontSize: "12px",
    paddingTop: "10px"
  },
  radioButton: {
    "& .PrivateSwitchBase-input-16": {
      width: "50%"
    }
  },
  form: {
    "& .MuiFormControlLabel-root": {
      paddingLeft: "20px",
      paddingBottom: "10px"
    }
  }
}))

const AuthenticationSetup = () => {
  const cls = useStyles()
  const [radioValue, setRadioValue] = useState("phone")
  const [isAuthContent, setIsAuthContent] = useState(true)
  const [isPhoneContent, setIsPhoneContent] = useState(false)
  const [isEmailContent, setIsEmailContent] = useState(false)
  const [isGoogleContent, setIsGoogleContent] = useState(false)
  const [googleAuthResponse, setGogleAuthResponse] = useState({})
  const googleAuth = useAuthentication()
  const formik = useFormik({})
  const handleRadioChange = event => {
    setRadioValue(event.target.value)
  }
  const handleClick = () => {
    setIsAuthContent(false)
    if (radioValue == "phone") setIsPhoneContent(true)
    if (radioValue == "email") setIsEmailContent(true)
    if (radioValue == "googleauth") {
      googleAuth.mutate(
        { two_factor_config: { method: "GOOGLE_AUTH" } },
        {
          onSuccess: response => {
            setGogleAuthResponse(response)
            setIsGoogleContent(true)
          },
          onError: () => {}
        }
      )
    }
  }
  const authenticationContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Setup 2 factor Authentication</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <RadioGroup
              defaultValue="phone"
              className={cls.radioButton}
              name="radio-buttons-group"
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="email"
                control={<NCRadio color={"primary"} />}
                label="Email"
              />
              <FormControlLabel
                value="phone"
                control={<NCRadio color={"primary"} />}
                label="Phone"
              />
              <FormControlLabel
                value="googleauth"
                control={<NCRadio color={"primary"} />}
                label="Google Authenticator"
              />
            </RadioGroup>
            <NCButton variant="contained" color="primary" onClick={handleClick}>
              Continue
            </NCButton>
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )

  return (
    <>
      {googleAuth.isLoading && <NCLoader open={true} />}
      {isAuthContent && <AuthComponent> {authenticationContent}</AuthComponent>}
      {isPhoneContent && <PhoneAuthentication />}
      {isEmailContent && <EmailAuthentication />}
      {isGoogleContent && (
        <GoogleAuthentication response={googleAuthResponse} />
      )}
    </>
  )
}

export default AuthenticationSetup
