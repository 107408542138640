import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import { Box, Grid } from "@material-ui/core"
import { useClientRequestApprove, useClientRequestApproveEdit } from "../../services/mutations"
import NCModal from "../../shared/NCModal"
import NCButton from "../../shared/NCButton"
import FormField from "../FormField"
import { useQueryClient } from "react-query"
import NCDatePicker from "../../shared/NCDatePicker"
import NCTimePicker from "../../shared/NCTimePicker"
import NCText from "../../shared/NCText"
import { formatAMPM, formatDate } from "../../libs/utils"
import { format, isValid } from "date-fns"

const ApproveRequestModal = ({
  modalState = false,
  handleClose,
  data = {}
}) => {
  const queryClient = useQueryClient()
  const approveRequest = data.actual_starting_date ? useClientRequestApproveEdit() : useClientRequestApprove()

  const formik = useFormik({
    initialValues: {
      actual_starting_date: data.actual_starting_date || "",
      pickup_time: data.pickup_time || "",
      bleg_pickup_time: data.bleg_pickup_time || ""
    },
    validationSchema: Yup.object().shape(
      {
        actual_starting_date: Yup.date()
          .typeError("Use format(MM/dd/YYYY)")
          .min(
            format(new Date().setUTCHours(0, 0, 0, 0), "MM/dd/yyyy"),
            "Actual starting date must be today or greater than that."
          )
          .required("Start Date is required"),
        pickup_time: Yup.date().typeError("Use format(hh:mm a)").when("bleg_pickup_time", (endDate, schema) => {
          if (endDate && endDate.length && endDate[0] && isValid(endDate[0])) {
            const timeBefore = new Date(new Date(endDate).getTime() - 60000)
            if (data.start_bell_time) {
              return schema.required("Pickup Time is required").max(
                new Date(timeBefore),
                "must be less then B-Leg time"
              )
            }
            return schema.max(
              new Date(timeBefore),
              "must be less then B-Leg time"
            )
          }
          return data.start_bell_time
            ? schema.required("Pickup Time is required")
            : schema
        }),
        bleg_pickup_time: Yup.date().typeError("Use format(hh:mm a)").when(
          "pickup_time",
          (startDate, schema) => {
            if (startDate && startDate.length && startDate[0] && isValid(startDate[0])) {
              const timeAfter = new Date(new Date(startDate).getTime() + 60000)
              if (data.end_bell_time) {
                return schema.required("ETA home arrival Time is required").min(
                  new Date(timeAfter),
                  "must be greater then pickup time"
                )
              }
              return schema.min(
                new Date(timeAfter),
                "must be greater then pickup time"
              )
            }
            return data.end_bell_time
              ? schema.required("ETA home arrival Time is required")
              : schema
          }
        )
        // pickup_time: data.start_bell_time ? Yup.string().required("Pickup Time is required") : Yup.string(),
        // bleg_pickup_time: data.end_bell_time ? Yup.string().required(
        //   "ETA home arrival Time is required"
        // ) : Yup.string()
      },
      ["bleg_pickup_time", "pickup_time"]
    ),
    enableReinitialize: true,
    onSubmit(values) {
      approveRequest.mutate(
        {
          ...values,
          id: data.id,
          pickup_time: data?.start_bell_time
            ? new Date(
              `${format(
                new Date(values.actual_starting_date),
                "yyyy-MM-dd"
              )} ${formatAMPM(values.pickup_time)}`
            ).toISOString()
            : null,
          bleg_pickup_time: data?.end_bell_time
            ? new Date(
              `${format(
                new Date(values.actual_starting_date),
                "yyyy-MM-dd"
              )} ${formatAMPM(values.bleg_pickup_time)}`
            ).toISOString()
            : null
        },
        {
          onSuccess: () => {
            if (data.actual_starting_date) {
              toast.success("Request has been edited successfully")
            } else {
              toast.success("Request has been approved successfully")
            }
            queryClient.refetchQueries(["getOverviewList"])
            queryClient.refetchQueries(["getNotificationData"])
            queryClient.refetchQueries(["getNotificationSummary"])
            handleClose()
          }
        }
      )
    }
  })

  const requestModal = (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box bgcolor={"#EDEDED"} p={1} borderRadius={"10px"}>
              <Grid container>
                <Grid item xs={12}>
                  <NCText variant={"subtitle"}>
                    <Box fontWeight={"bold"} mb={2}>
                      Request Detail
                    </Box>
                  </NCText>
                </Grid>
                <Grid item xs={4}>
                  <Box display={"flex"} gridGap={3}>
                    <NCText>
                      <Box fontSize={"12px"} fontWeight={"bold"}>
                        Start Date:
                      </Box>
                    </NCText>
                    <NCText>
                      <Box fontSize={"12px"} color={"#9CA3AF"}>
                        {formatDate(data?.starting_date)}
                      </Box>
                    </NCText>
                  </Box>
                </Grid>
                {data?.start_bell_time ? (
                  <Grid item xs={4}>
                    <Box display={"flex"} gridGap={3}>
                      <NCText>
                        <Box fontSize={"12px"} fontWeight={"bold"}>
                          A-Leg Bell time:
                        </Box>
                      </NCText>
                      <NCText>
                        <Box fontSize={"12px"} color={"#9CA3AF"}>
                          {formatAMPM(data?.start_bell_time)}
                        </Box>
                      </NCText>
                    </Box>
                  </Grid>
                ) : null}
                {data?.end_bell_time ? (
                  <Grid item xs={4}>
                    <Box display={"flex"} gridGap={3}>
                      <NCText>
                        <Box fontSize={"12px"} fontWeight={"bold"}>
                          B-Leg Bell time:
                        </Box>
                      </NCText>
                      <NCText>
                        <Box fontSize={"12px"} color={"#9CA3AF"}>
                          {formatAMPM(data?.end_bell_time)}
                        </Box>
                      </NCText>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormField
              as={NCDatePicker}
              label={"Actual Start Date"}
              name={"actual_starting_date"}
              onChange={v => formik.setFieldValue("actual_starting_date", v)}
              min={
                data?.starting_date || new Date().toISOString().split("T")[0]
              }
              dateTime={true}
              required={true}
              error={formik?.errors?.actual_starting_date}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            {data?.start_bell_time ? (
              <Grid
                item
                xs={data?.end_bell_time ? 6 : 12}
                style={{ marginRight: "8px" }}
              >
                <FormField
                  as={NCTimePicker}
                  label={"Pickup Time"}
                  placeholder={"Pickup Time"}
                  name={"pickup_time"}
                  onChange={v => formik.setFieldValue("pickup_time", v)}
                  required={true}
                  error={formik?.errors?.pickup_time}
                />
              </Grid>
            ) : null}
            {data?.end_bell_time ? (
              <Grid item xs={data?.start_bell_time ? 6 : 12}>
                <FormField
                  as={NCTimePicker}
                  label={"B-Leg ETA"}
                  placeholder={"B-Leg ETA"}
                  name={"bleg_pickup_time"}
                  onChange={v => formik.setFieldValue("bleg_pickup_time", v)}
                  required={true}
                  error={formik?.errors?.bleg_pickup_time}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  )

  return (
    <NCModal
      open={modalState}
      title={data?.actual_starting_date ? "Modify Request" : "Approve Request"}
      text={requestModal}
      onClose={() => handleClose()}
      submitButton={
        <NCButton
          variant="contained"
          color="primary"
          disableElevation
          onClick={formik.handleSubmit}
          style={{ width: "auto" }}
        >
          {data?.actual_starting_date ? "Update" : "Submit"}
        </NCButton>
      }
    />
  )
}
export default ApproveRequestModal
