import { useState } from "react"
import { Box, Grid, makeStyles } from "@material-ui/core"
import NCText from "../../shared/NCText"
import NCButton from "../../shared/NCButton"
import NCModal from "../../shared/NCModal"
import { Form, useFormik, FormikProvider, FieldArray } from "formik"
import * as Yup from "yup"
import { useAddAdmin } from "../../services/mutations"
import { toast } from "react-toastify"
import PhoneNumber from "../../components/PhoneNumber"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import TopNavBar from "../../components/TopNavBar"
import NCLoader from "../../shared/NCLoader"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { AddIcon, RemoveItemIcon } from "../../libs/icons"
import NCAddress from "../../shared/NCAddress"
import { useNavigate } from "react-router-dom"
import NotificationAlert from "../../components/notification/NotificationAlert"

const useStyles = makeStyles(() => ({
  btn: {
    display: "flex",
    justifyContent: "right"
  },
  saveButton: {
    width: "150px",
    color: "white"
  },
  cancelButton: {
    width: "150px",
    marginRight: "15px",
    color: "red",
    borderColor: "red"
  }
}))
const AddAdmin = () => {
  const nav = useNavigate()
  const cls = useStyles()
  const [open, setOpen] = useState(false)
  const modalTitle = "Discard changes"
  const modalText = "Are you sure you want to discard changes ?"
  const addAdminRequest = useAddAdmin()
  const handleClose = () => {
    setOpen(false)
  }
  const handleDiscard = () => {
    formik.resetForm()
    setOpen(false)
  }
  const formik = useFormik({
    initialValues: {
      company_name: "",
      company_address: "",
      company_email_address: "",
      company_repr: {
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      },
      employees: [
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: ""
        }
      ]
    },
    validationSchema: Yup.object().shape({
      company_name: Yup.string().required("District name is required"),
      company_address: Yup.string().required("District address is required"),
      company_email_address: Yup.string()
        .email("Invalid email address")
        .required("District email is required"),
      company_repr: Yup.object().shape({
        first_name: Yup.string().required("First name is required"),
        last_name: Yup.string().required("Last name is required"),
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        phone: Yup.string().required("Phone number is required")
      }),
      employees: Yup.array()
        .of(
          Yup.object().shape({
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Email name is required"),
            phone: Yup.string().required("Phone number is required")
          })
        )
        .min(1, "Minimum of 1 employee")
    }),
    onSubmit(values) {
      addAdminRequest.mutate(values, {
        onSuccess: () => {
          formik.resetForm()
          toast.success("Admin has been successfully added")
          // queryClient.invalidateQueries(["getAdminEmployeeList"])
          nav("/admins")
        }
      })
    }
  })
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box mb={2}>
          <NCText variant={"h4"} style={{ marginRight: "10px" }}>
            Add Admin
          </NCText>
        </Box>
        <Box>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <FormikProvider value={formik}>
        <Form className={cls.form} onSubmit={formik.handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title="Add District Details">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={"District Name"}
                      name={"company_name"}
                      required={true}
                      error={formik?.errors?.company_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={NCAddress}
                      label={"District Address"}
                      name={"company_address"}
                      onChange={e => formik.setFieldValue("company_address", e)}
                      required={true}
                      error={formik?.errors?.company_address}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={"District Email Address"}
                      name={"company_email_address"}
                      required={true}
                      error={formik?.errors?.company_email_address}
                    />
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title="Add District Representative">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={2}>
                    <FormField
                      label={"First Name"}
                      name={"company_repr.first_name"}
                      required={true}
                      error={formik?.errors?.company_repr?.first_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormField
                      label={"Last Name"}
                      name={"company_repr.last_name"}
                      required={true}
                      error={formik?.errors?.company_repr?.last_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={"Email Address"}
                      name={"company_repr.email"}
                      required={true}
                      error={formik?.errors?.company_repr?.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={PhoneNumber}
                      label={"Phone Number"}
                      name={"company_repr.phone"}
                      onChange={e =>
                        formik.setFieldValue("company_repr.phone", `+${e}`)
                      }
                      required={true}
                      error={formik?.errors?.company_repr?.phone}
                    />
                  </Grid>
                </Grid>
              </FormSection>
            </Grid>
            <Grid item xs={12} sm={8} md={12}>
              <FieldArray
                name="employees"
                render={arrayHelpers => (
                  <FormSection
                    title={
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <span>Employees</span>
                        </Grid>
                        <Grid item>
                          <NCButton
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone: ""
                              })
                            }
                            endIcon={
                              <NCSvgIcon component={AddIcon} inheritViewBox />
                            }
                          >
                            Add Employee
                          </NCButton>
                        </Grid>
                      </Grid>
                    }
                  >
                    <Grid container spacing={2}>
                      {formik.values.employees &&
                      formik.values.employees.length > 0
                        ? formik.values.employees.map((emp, index) => (
                            <>
                              <Grid item container spacing={1} xs={11}>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"First Name"}
                                    name={`employees[${index}].first_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.employees?.[index]
                                        ?.first_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"Last Name"}
                                    name={`employees[${index}].last_name`}
                                    required={true}
                                    error={
                                      formik?.errors?.employees?.[index]
                                        ?.last_name
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    label={"Email Address"}
                                    name={`employees[${index}].email`}
                                    required={true}
                                    error={
                                      formik?.errors?.employees?.[index]?.email
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  <FormField
                                    as={PhoneNumber}
                                    label={"Phone Number"}
                                    name={`employees[${index}].phone`}
                                    onChange={e =>
                                      formik.setFieldValue(
                                        `employees[${index}].phone`,
                                        `+${e}`
                                      )
                                    }
                                    required={true}
                                    error={
                                      formik?.errors?.employees?.[index]?.phone
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"flex-end"}
                                  mt={"30px"}
                                >
                                  <RemoveItemIcon
                                    onClick={() => arrayHelpers.remove(index)}
                                    className="cursor-pointer"
                                  />
                                </Box>
                              </Grid>
                            </>
                          ))
                        : null}
                    </Grid>
                  </FormSection>
                )}
              ></FieldArray>
            </Grid>
          </Grid>
          <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
                // className={cls.btn}
              >
                <Grid item>
                  <NCButton
                    onClick={() => nav("/admins")}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </NCButton>
                </Grid>
                <Grid item>
                  <NCButton variant="contained" color="primary" type="submit">
                    Save
                  </NCButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <NCModal
            open={open}
            title={modalTitle}
            text={modalText}
            onClose={handleClose}
            handleDiscard={handleDiscard}
          />
          {addAdminRequest.isLoading && <NCLoader open={true} />}
        </Form>
      </FormikProvider>
    </Grid>
  )
}

export default AddAdmin
