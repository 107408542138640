import { useState } from "react"
import { Grid, Box, ButtonGroup, Button, makeStyles } from "@material-ui/core"
import { useParams } from "react-router-dom"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import SelectedClient from "../../components/client/SelectedClient"
import ClientRequestList from "../admin/ClientRequestList"
import { useGetClientAuditLog } from "../../services/queries"
import NCAuditButton from "../../shared/NCAuditButton"
import { loggedInUser } from "../../libs/utils"
import NotificationAlert from "../../components/notification/NotificationAlert"

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: "20px",
    fontSize: "12px"
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    fintWeight: "600"
  },
  subSectionTitle: {
    color: "#9CA3AF",
    fontSize: "12px"
  },
  "& .MuiBox-root": {
    display: "flex"
  }
}))
const ClientDetail = () => {
  const cls = useStyles()
  const user = loggedInUser()
  let { clientId } = useParams()
  const [selectedTab, setSelectedTab] = useState(1)
  const { isLoading, data } = useGetClientAuditLog(
    {
      client_id: clientId
    },
    { enabled: clientId && user?.user_type === 1 ? true : false }
  )
  const Auditdata = data?.data?.data?.results || []
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Box mb={2}>
              <NCText variant={"h4"} style={{ marginRight: "10px" }}>
                Client Details
              </NCText>
            </Box>
            <Box display={"flex"}>
              {user.user_type === 1 && clientId ? (
                <Box mb={2} mr={1}>
                  <NCAuditButton data={Auditdata} isLoading={isLoading} />
                </Box>
              ) : null}
              <Box paddingLeft={"0px"}>
                <NotificationAlert />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <TopNavBar />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid mb={2}>
            <Box mb={2}>
              <ButtonGroup disableElevation>
                <Button
                  className={cls.button}
                  variant={selectedTab === 1 ? "contained" : "outlined"}
                  onClick={() => setSelectedTab(1)}
                  color={selectedTab === 1 ? "primary" : ""}
                >
                  Client Details
                </Button>
                <Button
                  className={cls.button}
                  variant={selectedTab === 1 ? "outlined" : "contained"}
                  onClick={() => setSelectedTab(2)}
                  color={selectedTab === 1 ? "" : "primary"}
                >
                  Client Request
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={8} md={12}>
          {selectedTab === 1 ? (
            <SelectedClient clientId={clientId} />
          ) : (
            <ClientRequestList clientId={clientId} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ClientDetail
