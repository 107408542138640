import { useState, useEffect } from "react"
import { Grid, makeStyles, Paper } from "@material-ui/core"
import NCContent from "../shared/NCContent"
import NCInput from "../shared/NCInput"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import AuthComponent from "../components/AuthComponent"
import { Form, Field, useFormik, FormikProvider } from "formik"
import { useCodeVerify } from "../services/mutations"
import QRCode from "qrcode"
import successLogo from "../assets/images/successLogo.svg"
import { useNavigate } from "react-router"

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: "20px",
    marginTop: "6px",
    width: "100%"
  },
  paper: {
    padding: "24px 30px",
    // marginTop: "70px",
    // marginBottom: "80px",
    borderRadius: "20px"
  },
  title: {
    marginTop: "12px",
    marginBottom: "30px"
  },
  text: {
    fontSize: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "52px",
    color: "#6B7280"
  },
  errorMessage: {
    color: "red",
    fontSize: "12px"
  },
  form: {
    "& .MuiOutlinedInput-root": {
      marginLeft: "52px",
      marginRight: "10px"
    }
  },
  qrCode: {
    display: "flex",
    paddingLeft: "45px",
    width: "100px",
    paddingBottom: "10px"
  }
}))

const GoogleAuthentication = props => {
  const cls = useStyles()
  const googleAuthURL =
    props?.response?.data?.data?.two_factor_config?.google_auth_info.link
  const verifyAuthcode = useCodeVerify()
  const [isVerifyAuth, setIsVerifyAuth] = useState(false)
  const nav = useNavigate()

  const formik = useFormik({
    initialValues: {
      verificationcode: ""
    },
    onSubmit(values) {
      verifyAuthcode.mutate(
        { otp: values.verificationcode, id: props?.response?.data?.data?.id },
        {
          onSuccess: () => {
            setIsVerifyAuth(true)
          },
          onError: () => {
            //console.log("error block")
          }
        }
      )
    }
  })
  const [qrCodeUrl, setQrCodeUrl] = useState("")
  useEffect(() => {
    QRCode.toDataURL(googleAuthURL).then(setQrCodeUrl)
  }, [])

  const verifySetupContent = (
    <Grid item xs={8}>
      <Paper elevation={0} className={cls.paper}>
        <NCTitle className={cls.title}>Setup Google Authenticator</NCTitle>
        <FormikProvider value={formik}>
          <Form className={cls.form} onSubmit={formik.handleSubmit}>
            <NCContent>Step 1: Download an authenticator app</NCContent>
            <NCText className={cls.text}>
              Install Google authenticator on your mobile device
            </NCText>
            <NCContent>Step 2: Scan the QR code</NCContent>
            <NCText className={cls.text}>
              Open the authenticator app and scan the code below using your
              phone's camera
            </NCText>
            <img src={qrCodeUrl} alt="qrcodeurl" className={cls.qrCode} />
            <NCContent>Step 3: Verify the code</NCContent>
            <NCText className={cls.text}>
              Enter the authentication code generated by the app
            </NCText>
            <NCText
              className={cls.text}
              style={{
                fontWeight: "550",
                paddingTop: "0px",
                paddingBottom: "5px"
              }}
            >
              Verification Code *
            </NCText>
            <Field
              as={NCInput}
              className={cls.input}
              placeholder="Enter Verification Code"
              name="verificationcode"
            />
            <NCButton variant="contained" color="primary" type="submit">
              Send Code
            </NCButton>
          </Form>
        </FormikProvider>
      </Paper>
    </Grid>
  )
  const verificationSuccessContent = (
    <Grid item xs={8}>
      <Paper className={cls.paper}>
        <img src={successLogo} style={{ width: "25px" }} />
        <NCTitle className={cls.title}>
          2 Factor Authentication is Setup
        </NCTitle>
        <NCText className={cls.text}>
          You can disable it from the settings.
        </NCText>
        <NCButton
          variant="contained"
          color="primary"
          onClick={() => nav("/logout")}
        >
          Continue{" "}
        </NCButton>
      </Paper>
    </Grid>
  )
  return (
    <AuthComponent>
      {isVerifyAuth ? verificationSuccessContent : verifySetupContent}
    </AuthComponent>
  )
}

export default GoogleAuthentication
