import { Route, Routes as DomRoutes } from "react-router-dom"
import LoginPage from "./containers/LoginPage"
import AuthenticationPage from "./containers/AuthenticationPage"
import ResetPassword from "./containers/ResetPassword"
import NewPassword from "./containers/NewPassword"
import AuthenticatedRoute from "./AuthenticatedRoute"
import ClientList from "./containers/client/List"
import SubAdminList from "./containers/subadmin/List"
import React from "react"
import AuthenticationSetup from "./containers/AuthenticationSetup"
import AddAdmin from "./containers/admin/AddAdmin"
import AddSubAdmin from "./containers/subadmin/add"
import OverviewList from "./containers/request/List"
import AdminList from "./containers/admin/List"
import AddClient from "./containers/client/add"
import ClientDetail from "./containers/client/Detail"
import SelectedAdmin from "./containers/admin/SelectedAdmin"
import Setting from "./containers/setting"
import Logout from "./containers/Logout"
import AddRequest from "./containers/request/AddRequest"
import FeedbackList from "./containers/feedback/List"
import RequestDetails from "./containers/request/RequestDetails"
import PrivacyPolicy from "./containers/meta/PrivacyPolicy"
import TermsandConditions from "./containers/meta/TermsandConditions"
import DispatcherList from "./containers/dispatcher/list"
import AddDispatcher from "./containers/dispatcher/add"
// import AddAidMonitor from "./containers/aidMonitor/add"
// import DriverList from "./containers/driver/list"
// import AddDriver from "./containers/driver/add"
import SubAdminDetail from "./containers/subadmin/Detail"
// import VehicleList from "./containers/vehicle/List"
// import VehicleDetail from "./containers/vehicle/details"
// import AidMonitorList from "./containers/aidMonitor/list"
// import DriverDetail from "./containers/driver/details"
// import Appointments from "./components/appointments/Appointments"
// import DispatcherNotificationList from "./containers/dispatcherNotification/List"

const Routes = () => {
  return (
    <DomRoutes>
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path="/overview" element={<OverviewList />} />
        <Route path="/admins" element={<AdminList />} />
        <Route exact path="/admins/add-admin" element={<AddAdmin />} />
        <Route exact path="/admins/:adminId" element={<SelectedAdmin />} />
        <Route path="/clients" element={<ClientList />} />
        <Route path="/clients/add-client" element={<AddClient />} />
        <Route exact path="/clients/:clientId" element={<ClientDetail />} />
        {/* <Route exact path="/vehicles" element={<VehicleList />} />
        <Route exact path="/vehicles/:vehicleId" element={<VehicleDetail />} /> */}
        {/* <Route path="/map" element={<TestMap />} /> */}
        <Route path="/feedback" element={<FeedbackList />} />
        <Route path="/setting" element={<Setting />} />
        <Route path="/logout" element={<Logout />} />
        <Route exact path="/subadmins/add" element={<AddSubAdmin />} />
        {/* <Route exact path="/appointments" element={<Appointments />} /> */}
        {/* <Route exact path="/ridedetails" element={<RideDetails />} /> */}
        <Route
          exact
          path="/subadmins/:subAdminId"
          element={<SubAdminDetail />}
        />
        <Route exact path="/subadmins" element={<SubAdminList />} />
        <Route exact path="/overview/add-request" element={<AddRequest />} />
        <Route exact path="/overview/:id" element={<RequestDetails />} />
        <Route path="/" element={<OverviewList />} />
        <Route path="/dispatchers" element={<DispatcherList />} />
        <Route path="/dispatchers/add-dispatcher" element={<AddDispatcher />} />
        <Route path="/dispatchers/:dispatcherId" element={<AddDispatcher />} />
        {/* <Route path="/aidmonitor" element={<AidMonitorList />} />
        <Route path="/aidmonitor/add-aidmonitor" element={<AddAidMonitor />} />
  <Route path="/aidmonitor/:id" element={<AddAidMonitor />} />*/}
        {/* <Route path="/drivers" element={<DriverList />} />
        <Route path="/drivers/add-driver" element={<AddDriver />} />
        <Route path="/drivers/:driverId" element={<DriverDetail />} />
        <Route path="/drivers/:id/edit" element={<AddDriver />} /> */}
        {/* <Route path="/notification" element={<DispatcherNotificationList />} /> */}

        <Route path="*" element={<div>Development is under progress</div>} />
      </Route>
      <Route exact path="/authsetup" element={<AuthenticationSetup />} />
      <Route exact path="/authenticate" element={<AuthenticationPage />} />
      <Route exact path="/resetpassword" element={<ResetPassword />} />
      <Route exact path="/reset-password-confirm" element={<NewPassword />} />
      <Route exact path="/login" element={<LoginPage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route exact path="/terms-conditions" element={<TermsandConditions />} />
    </DomRoutes>
  )
}

export default Routes
