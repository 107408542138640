import { Navigate, Outlet } from "react-router-dom"
import Sidebar from "../src/components/Sidebar"
import { isUserLoggedIn } from "./libs/utils"

const AuthenticatedRoute = () => {
  if (!isUserLoggedIn()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" replace />
  }
  return (
    <div>
      <Sidebar>
        <Outlet />
      </Sidebar>
    </div>
  )
}

export default AuthenticatedRoute
