import { useMutation } from "react-query"
import API from "../libs/api"
import { API_PATH } from "../libs/config"
import { jsonToFormData } from "../libs/utils"
import { STORAGE } from "../libs/storage"

export const useLogin = () => {
  return useMutation(data => API.post(API_PATH.LOGIN, data))
}

export const useResetPassword = () => {
  return useMutation(data => API.post(API_PATH.RESET_PASSWORD, data))
}

export const useNewPassword = () => {
  return useMutation(data => API.post(API_PATH.NEW_PASSWORD, data))
}
export const useChangePassword = () => {
  return useMutation(data => API.post(API_PATH.CHANGE_PASSWORD, data))
}

export const useAuthentication = () => {
  return useMutation(data => API.post(API_PATH.AUTHENTICATION, data))
}
export const useAddAdmin = () => {
  return useMutation(data => API.post(API_PATH.ADD_ADMIN, data))
}
export const useUpdateAdmin = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.ADD_ADMIN}${data.id}/`, data)
  )
}
export const useDeleteAdmin = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.ADD_ADMIN}${id}/`)
  })
}

export const useAddClient = () => {
  return useMutation(data => {
    const form_data = jsonToFormData(data)
    return API.post(API_PATH.CLIENT_LIST, form_data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}

export const useUpdateClient = () => {
  return useMutation(data => {
    return API.patch(
      `${API_PATH.CLIENT_LIST}${data.id}/`,
      jsonToFormData(data),
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    )
  })
}

export const useDeleteClient = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.CLIENT_LIST}${id}/`)
  })
}

export const useActivateClient = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.CLIENT_LIST}${id}/activate-client/`)
  })
}

export const useAddSubadmin = () => {
  return useMutation(data => {
    const form_data = jsonToFormData(data)
    return API.post(API_PATH.SUB_ADMIN_LIST, form_data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}
export const useUpdateSubAdmin = () => {
  return useMutation(data => {
    return API.patch(`${API_PATH.SUB_ADMIN_LIST}${data.id}/`, data)
  })
}

export const useAddSubEmployee = () => {
  return useMutation(data => API.post(API_PATH.SUB_EMPLOYEE_LIST, data))
}

export const useUpdateSubEmployee = () => {
  return useMutation(data => {
    return API.patch(`${API_PATH.SUB_EMPLOYEE_LIST}${data.id}/`, data)
  })
}

export const useDeleteSubEmployee = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.SUB_EMPLOYEE_LIST}${id}/`)
  })
}
export const useActivateSubEmployee = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.SUB_EMPLOYEE_LIST}${id}/activate-sub-employee/`)
  })
}

export const useAddEmployee = () => {
  return useMutation(data => API.post(API_PATH.ADD_EMPLOYEE, data))
}
export const useUpdateEmployee = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.ADD_EMPLOYEE}${data.id}/`, data)
  )
}
export const useDeleteEmployee = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.ADD_EMPLOYEE}${id}/`)
  })
}
export const useActivateEmployee = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.ADD_EMPLOYEE}${id}/activate-employee/`)
  })
}

export const useClientRequest = () => {
  return useMutation(data => {
    const form_data = jsonToFormData(data)
    return API.post(API_PATH.CLIENT_REQUESTS, form_data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}

export const useCodeVerify = () => {
  return useMutation(data =>
    API.post(`${API_PATH.VERIFY_AUTH_SETUP}${data.id}/verify/`, data)
  )
}

export const useUpdateRepresentative = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.REPRESENTATIVE_LIST}${data.id}/`, data)
  )
}
export const useLogout = () => {
  return useMutation(() => API.post(API_PATH.LOGOUT))
}

export const useSendOtp = () => {
  return useMutation(data =>
    API.post(
      `${API_PATH.SEND_OTP}${data?.data?.two_factor_identifier}/send_otp/`
    )
  )
}

export const useResendOtp = () => {
  return useMutation(() =>
    API.post(
      `${API_PATH.SEND_OTP}${STORAGE.getItem("noahCares").two_factor_identifier
      }/send_otp/`
    )
  )
}

export const useVerifyOtp = () => {
  return useMutation(data =>
    API.post(
      `${API_PATH.VERIFY_OTP}${STORAGE.getItem("noahCares").two_factor_identifier
      }/verify_otp/`,
      data
    )
  )
}
export const useFeedbackAdminReply = () => {
  return useMutation(data =>
    API.post(API_PATH.FEEDBACK_ADMIN_REPLY(data.id), data)
  )
}

export const useDeactivateUser = () => {
  return useMutation(id => API.delete(`${API_PATH.USER_PROFILE}${id}/`))
}

export const useUpdateEmergency = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.EMERGENCY_CONTACT}${data.id}/`, data)
  )
}

export const useAddGuardian = () => {
  return useMutation(data => API.post(API_PATH.GET_GUARDIAN_LIST, data))
}
export const useUpdateGuardian = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.GET_GUARDIAN_LIST}${data.id}/`, data)
  )
}

export const useDeleteGuardian = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.GET_GUARDIAN_LIST}${id}/`)
  })
}

export const useActivateGuardian = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.GET_GUARDIAN_LIST}${id}/activate-guardian/`)
  })
}

export const useAddFeedback = () => {
  return useMutation(data => {
    const form_data = jsonToFormData(data)
    return API.post(API_PATH.ADD_FEEDBACK, form_data, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}

export const useDeactivateTwoFactor = () => {
  return useMutation(() => API.delete(API_PATH.TWO_FACTOR_DEACTIVATE))
}

export const useUpdateNotificationSetting = () => {
  return useMutation(data =>
    API.put(`${API_PATH.NOTIFICATION_SETTING}${data.id}/`, data)
  )
}

export const useReadNotification = () => {
  return useMutation(data => API.post(API_PATH.READ_NOTIFICATION, data))
}

export const useReadAllNotification = () => {
  return useMutation(data =>
    API.post(API_PATH.MARK_ALL_NOTIFICATION_AS_READ, data)
  )
}

export const useAddDriver = () => {
  return useMutation(data => {
    return API.post(API_PATH.DRIVER, jsonToFormData(data), {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}

export const useUpdateDriver = () => {
  return useMutation(data => {
    return API.patch(`${API_PATH.DRIVER}${data.id}/`, jsonToFormData(data), {
      headers: { "Content-Type": "multipart/form-data" }
    })
  })
}

export const useDeleteDriver = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.DRIVER}${id}/`)
  })
}

export const useActivateDriver = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.DRIVER}${id}/activate-driver/`)
  })
}

export const useAssignDispatcherToDriver = () => {
  return useMutation(data => {
    return API.post(`${API_PATH.DRIVER}${data.id}/assign-dispatcher/`, data)
  })
}

export const useAddDispatcher = () => {
  return useMutation(data => API.post(API_PATH.ADD_DISPATCHER, data))
}

export const useUpdateDispatcher = () => {
  return useMutation(data => {
    return API.patch(`${API_PATH.DISPATCHER_LIST}${data.id}/`, data)
  })
}

export const useDeleteDispatcher = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.DISPATCHER_LIST}${id}/`)
  })
}

export const useActivateDispatcher = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.DISPATCHER_LIST}${id}/activate-dispatcher/`)
  })
}

export const useActivateAidMonitor = () => {
  return useMutation(id => {
    return API.post(`${API_PATH.AID_MONITOR}${id}/activate-aid-monitor/`)
  })
}

export const useDeleteAidMonitor = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.AID_MONITOR}${id}/`)
  })
}

export const useAddAidMonitor = () => {
  return useMutation(data => API.post(API_PATH.AID_MONITOR, data))
}

export const useUpdateAidMonitor = () => {
  return useMutation(data => {
    return API.patch(`${API_PATH.AID_MONITOR}${data.id}/`, data)
  })
}

export const useClientRequestApprove = () => {
  return useMutation(data =>
    API.post(
      `${API_PATH.CLIENT_REQUEST_ACTION_STATUS}${data.id}/approve-request/`,
      data
    )
  )
}
export const useClientRequestApproveEdit = () => {
  return useMutation(data =>
    API.patch(
      `${API_PATH.CLIENT_REQUEST_ACTION_STATUS}${data.id}/`,
      data
    )
  )
}

export const useClientRequestDecline = () => {
  return useMutation(id =>
    API.post(`${API_PATH.CLIENT_REQUEST_ACTION_STATUS}${id}/deny-request/`)
  )
}

export const useDriverAvailability = () => {
  return useMutation(data => API.post(API_PATH.ADD_DRIVER_AVAILABILITY, data))
}

export const useDeleteDriverAvailability = () => {
  return useMutation(id => {
    return API.delete(`${API_PATH.DRIVER_AVAILABILITY}${id}/`)
  })
}

export const useAssignDriver = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.ASSIGN_DRIVER(data.id)}`, data)
  )
}
export const useApproveClientRequest = () => {
  return useMutation(data =>
    API.patch(`${API_PATH.APPROVE_CLIENT_REQUEST(data.id)}`, data)
  )
}

export const usePutClientOnHold = () => {
  return useMutation(id =>
    API.post(API_PATH.PUT_ON_HOLD(id))
  )
}
export const useReleaseClientOnHold = () => {
  return useMutation(id =>
    API.post(API_PATH.RELEASE_HOLD(id))
  )
}
