import { FormLabel, makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
    fontSize: "24px",
    fontWeight: 700
  }
}))

const NCTitle = props => {
  const cls = useStyles()

  return (
    <FormLabel
      {...props}
      component="div"
      className={clsx(cls.root, props.className)}
    />
  )
}

export default NCTitle
