import { SvgIcon, makeStyles } from "@material-ui/core"
import clsx from "clsx"

const useStyles = makeStyles({
  custom: {
    fill: "none !important",
    fontSize: "inherit !important",
    position: "relative",
    top: "3px"
  }
})

const NCSvgIcon = props => {
  const cls = useStyles()
  return <SvgIcon {...props} className={clsx(cls.custom, props.className)} />
}

export default NCSvgIcon
