import { useNavigate } from "react-router-dom"
import { STORAGE } from "../libs/storage"
import { useEffect } from "react"
import { useLogout } from "../services/mutations"

const Logout = () => {
  const nav = useNavigate()
  const logoutApi = useLogout()
  useEffect(() => {
    logoutApi.mutate(
      {},
      {
        onSuccess: () => {
          STORAGE.clear()
          nav("/login")
        }
      }
    )
  }, [])

  return null
}

export default Logout
