import { Box, Grid } from "@material-ui/core"
import FormField from "../../components/FormField"
import NCButton from "../../shared/NCButton"
import { CloseIcon, DeleteIcon, UploadIcon } from "../../libs/icons"
import NCTitle from "../../shared/NCTitle"
import NCLoader from "../../shared/NCLoader"
import { Form, FormikProvider, useFormik } from "formik"
import * as Yup from "yup"
import { useGetClients } from "../../services/queries"
import { FEEDBACK_TYPE } from "../../libs/config"
import { useAddFeedback } from "../../services/mutations"
// import { toast } from "react-toastify"
import NCAutocomplete from "../../shared/NCAutocomplete"
import withContext from "../../components/DrawerConsumer"
// import { useRef } from "react"
import { toast } from "react-toastify"
import { useState } from "react"
import { useQueryClient } from "react-query"
import NCText from "../../shared/NCText"
import { useRef } from "react"
// import { useEffect } from "react"
// import { loggedInUser } from "../../libs/utils"
const AddFeedback = props => {
  const { showChatWindow } = props
  const [search, setSearch] = useState()
  const queryClient = useQueryClient()
  const attachmentRef = useRef()
  //const user = loggedInUser()
  const addFeedbackApi = useAddFeedback()
  const { data, isLoading } = useGetClients(null, search)
  const { results: clientData } = data?.data?.data || {}
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Category is required"),
      on_behalf_of_client: Yup.string().required("Client is required"),
      title: Yup.string().required("Title is required"),
      message: Yup.string().required("Message is required")
    }),
    onSubmit: values => {
      addFeedbackApi.mutate(values, {
        onSuccess: () => {
          toast.success("Feedback has been sent successfully")
          queryClient.invalidateQueries(["getFeedbackList"])
          queryClient.invalidateQueries(["getNotificationData"])
          queryClient.refetchQueries(["getNotificationSummary"])
          // props.setEmailMessage(
          //   "Please check your inbox and spam/junk folder for the feedback email."
          // )
          // props.showEmailModal(true)
          showChatWindow(false)
          formik.resetForm()
        }
      })
    }
  })

  // const showRef = useRef()
  // useEffect(() => {
  //   if (user.user_type === 2 || user.user_type === 3) {
  //     let handler = e => {
  //       if (!showRef.current.contains(e.target)) {
  //         showChatWindow(false)
  //       }
  //     }
  //     document.addEventListener("mousedown", handler)

  //     return () => {
  //       document.removeEventListener("mousedown", handler)
  //     }
  //   }
  // })

  return (
    <div>
      <Box position={"fixed"} right={"0px"} bottom={"10px"}>
        {/* {chatWindow ? ( */}

        <Box
          maxWidth={300}
          bgcolor={"#fff"}
          borderRadius={"20px"}
          border={"1px solid #ccc"}
          p={3}
          mr={1}
          mb={1}
        >
          <FormikProvider value={formik}>
            <Form onSubmit={formik.handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    mb={2}
                  >
                    <Box>
                      <NCTitle>Submit Feedback</NCTitle>
                    </Box>
                    <Box
                      border={"1px solid red"}
                      p={0.5}
                      className="cursor-pointer"
                    >
                      <CloseIcon onClick={() => showChatWindow(false)} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    as={NCAutocomplete}
                    options={
                      Object.keys(FEEDBACK_TYPE).map(key => {
                        return { id: key, name: FEEDBACK_TYPE[key] }
                      }) || []
                    }
                    label={"Feedback Category"}
                    name={"type"}
                    onChange={v => {
                      formik.setFieldValue("type", v)
                    }}
                    required={true}
                    error={formik?.errors?.type}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    as={NCAutocomplete}
                    options={clientData || []}
                    label={"Select Client"}
                    name={"on_behalf_of_client"}
                    onChange={v => {
                      formik.setFieldValue("on_behalf_of_client", v)
                    }}
                    loading={isLoading}
                    onSearch={v => setSearch(v)}
                    required={true}
                    error={formik?.errors?.on_behalf_of_client}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={"Title"}
                    name={"title"}
                    required={true}
                    error={formik?.errors?.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormField
                    label={"Message"}
                    name={"message"}
                    required={true}
                    error={formik?.errors?.message}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <label htmlFor="upload-doc">
                      <input
                        style={{ display: "none" }}
                        id="upload-doc"
                        name="upload-doc"
                        ref={attachmentRef}
                        type="file"
                        value={formik?.values?.document?.files}
                        onChange={e => {
                          formik.setFieldValue("document", e.target.files[0])
                        }}
                      />
                      {formik?.values?.document ? null : (
                        <Box className="cursor-pointer">
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item>
                              <UploadIcon />
                            </Grid>
                            <Grid item>
                              <NCText color={"primary"}>Add Attachement</NCText>
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </label>
                    {formik?.values?.document ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <NCText color={"primary"}>
                            {formik?.values?.document?.name}
                          </NCText>
                        </Grid>
                        <Grid item>
                          <DeleteIcon
                            className="cursor-pointer"
                            onClick={() => {
                              attachmentRef.current.value = null
                              formik.setFieldValue("document", null)
                            }}
                          />
                        </Grid>
                      </Grid>
                    ) : null}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <NCButton variant="contained" color="primary" type="submit">
                    Send Feedback
                  </NCButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </Box>
        {/* ) : (
        <Box mr={-5} mb={-3}>
          <ChatIcon
            onClick={() => showChatWindow(!chatWindow)}
            className="cursor-pointer"
            width={"60%"}
          />
        </Box>
      )} */}
        {addFeedbackApi.isLoading && <NCLoader open={true} />}
      </Box>
    </div>
  )
}

export default withContext(AddFeedback)
