import { Box, Grid } from "@material-ui/core"
import NCText from "../../shared/NCText"
import TopNavBar from "../../components/TopNavBar"
import { FieldArray, Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import FormSection from "../../components/FormSection"
import FormField from "../../components/FormField"
import PhoneNumber from "../../components/PhoneNumber"
import NCButton from "../../shared/NCButton"
import NCSvgIcon from "../../shared/NCSvgIcon"
import { AddIcon, RemoveItemIcon } from "../../libs/icons"
import { useAddSubadmin } from "../../services/mutations"
import { toast } from "react-toastify"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { loggedInUser } from "../../libs/utils"
import NCAddress from "../../shared/NCAddress"
import NCAutocomplete from "../../shared/NCAutocomplete"
import NotificationAlert from "../../components/notification/NotificationAlert"
import { useGetAdmin } from "../../services/queries"
import { useState } from "react"

const AddSubAdmin = () => {
  //const cls = useStyles()
  const [search, setSearch] = useState()
  const { profile_attributes, user_type } = loggedInUser()
  const addSubadmin = useAddSubadmin()
  const queryClient = useQueryClient()
  const { data, isLoading } = useGetAdmin(null, search, null, null, { enabled: user_type === 1 ? true : false })
  const adminList =
    user_type === 1
      ? data?.data?.data?.results || []
      : [{ ...profile_attributes }]
  const nav = useNavigate()
  const formik = useFormik({
    initialValues: {
      name: "",
      admin: "",
      sub_employees: [
        {
          first_name: "",
          last_name: "",
          email: "",
          phone: ""
        }
      ]
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      admin: Yup.string().required("Institution is required"),
      address: Yup.string().required("Address is required"),
      sub_employees: Yup.array()
        .of(
          Yup.object().shape({
            first_name: Yup.string(),//.required("First name is required"),
            last_name: Yup.string(),//.required("Last name is required"),
            phone: Yup.string(),//.required("Phone number is required"),
            email: Yup.string()
              .email("Invalid email address")
            //.required("Email is required")
          })
        )
      // .required("Must have guardian")
      //.min(1, "Minimum of 1 guardian")
    }),
    onSubmit: (values, { resetForm }) => {
      const { sub_employees, ...remainingValues } = values;
      let count = 0
      sub_employees.map(emp => {
        if (emp.email) {
          count++;
        }
      })
      let input = remainingValues
      if (sub_employees && sub_employees.length && count) {
        input = values
      }
      addSubadmin.mutate(input, {
        onSuccess: async () => {
          toast.success("Subadmin (School) has been added successfully")
          resetForm({ values: "" })
          queryClient.invalidateQueries(["getSubAdminList"])
          nav("/subadmins")
        }
      })
    }
  })
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box mb={2}>
          <NCText variant={"h4"}>Add Sub-admins (School)</NCText>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <NotificationAlert />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <TopNavBar />
        </Box>
      </Grid>
      <FormikProvider value={formik}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8} md={12}>
              <FormSection title=" Add Sub-admin (School) Details">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <FormField
                      label={"Name"}
                      name={"name"}
                      required={true}
                      error={formik?.errors?.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={NCAutocomplete}
                      options={adminList.map(admin => {
                        return { id: admin.id, name: admin.company_name }
                      })}
                      label={"Select Institution"}
                      name={"admin"}
                      onChange={v => {
                        formik.setFieldValue("admin", v)
                      }}
                      loading={isLoading}
                      onSearch={v => setSearch(v)}
                      required={true}
                      error={formik?.errors?.admin}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormField
                      as={NCAddress}
                      label={"Address"}
                      name={"address"}
                      onChange={e => formik.setFieldValue("address", e)}
                      required={true}
                      error={formik?.errors?.address}
                    />
                  </Grid>
                </Grid>
              </FormSection>
              <FieldArray
                name="sub_employees"
                render={arrayHelpers => (
                  <FormSection
                    title={
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <span>Employees</span>
                        </Grid>
                        <Grid item>
                          <NCButton
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              arrayHelpers.push({
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone: ""
                              })
                            }
                            endIcon={
                              <NCSvgIcon component={AddIcon} inheritViewBox />
                            }
                          >
                            Add Employees
                          </NCButton>
                        </Grid>
                      </Grid>
                    }
                  >
                    <Grid container spacing={2}>
                      {formik.values.sub_employees &&
                        formik.values.sub_employees.length > 0
                        ? formik.values.sub_employees.map((guard, index) => (
                          <>
                            <Grid item container spacing={1} xs={11}>
                              <Grid item xs={12} md={3}>
                                <FormField
                                  label={"First Name"}
                                  name={`sub_employees[${index}].first_name`}
                                  required={true}
                                  error={
                                    formik?.errors?.sub_employees?.[index]
                                      ?.first_name
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FormField
                                  label={"Last Name"}
                                  name={`sub_employees[${index}].last_name`}
                                  required={true}
                                  error={
                                    formik?.errors?.sub_employees?.[index]
                                      ?.last_name
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FormField
                                  label={"Email"}
                                  name={`sub_employees[${index}].email`}
                                  required={true}
                                  error={
                                    formik?.errors?.sub_employees?.[index]
                                      ?.email
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} md={3}>
                                <FormField
                                  as={PhoneNumber}
                                  label={"Phone Number"}
                                  name={`sub_employees[${index}].phone`}
                                  onChange={e =>
                                    formik.setFieldValue(
                                      `sub_employees[${index}].phone`,
                                      `+${e}`
                                    )
                                  }
                                  required={true}
                                  error={
                                    formik?.errors?.sub_employees?.[index]
                                      ?.phone
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                display={"flex"}
                                justifyContent={"flex-end"}
                                mt={"30px"}
                              >
                                <RemoveItemIcon
                                  onClick={() => arrayHelpers.remove(index)}
                                  className="cursor-pointer"
                                />
                              </Box>
                            </Grid>
                          </>
                        ))
                        : ""}
                    </Grid>
                  </FormSection>
                )}
              ></FieldArray>
            </Grid>
          </Grid>
          <Box bgcolor={"#F3FAFC"} p={2} mx={-3} ml={-4} display={"flex"}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <NCButton
                    onClick={() => nav("/subadmins")}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </NCButton>
                </Grid>
                <Grid item>
                  <NCButton variant="contained" color="primary" type="submit">
                    Save
                  </NCButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Form>
      </FormikProvider>
    </Grid>
  )
}
export default AddSubAdmin
