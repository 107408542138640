import React, { useEffect } from "react"
import clsx from "clsx"
import { Box } from "@material-ui/core"
import { useTable, useFilters, useSortBy, useAbsoluteLayout } from "react-table"
import "./table.scss"
import { AscendingIcon, DescendingIcon } from "../../libs/icons"
import NCText from "../NCText"
import Footer from "./Footer"
import NCSvgIcon from "../NCSvgIcon"
import NCTooltip from "../NCTooltip"

const NCTable = ({
  columns,
  data,
  page,
  onNext,
  onPrev,
  pageCount,
  gotoPage,
  pageSize,
  setPageSize,
  sorting,
  setSorting,
  filter = [],
  setFilter,
  hidePagination = false,
  inlineFooter = false
  // isLoading
}) => {
  const tableData = React.useMemo(() => data, [data])
  const tableColumns = React.useMemo(() => columns, [columns])
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: "",
      width: 150
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy, filters }
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        sortBy: sorting
          ? sorting.startsWith("-")
            ? [{ id: sorting.replace("-", ""), desc: true }]
            : []
          : [],
        filters: filter
      },
      manualSorting: true,
      manualFilters: true,
      defaultCanFilter: false,
      defaultColumn
    },
    useAbsoluteLayout,
    useFilters,
    useSortBy
  )
  useEffect(() => {
    if (sortBy && sortBy.length) {
      if (sortBy[0].desc) {
        setSorting(`-${sortBy[0].id}`)
      } else {
        setSorting(sortBy[0].id)
      }
    }
  }, [sortBy])

  useEffect(() => {
    if (filters && filters.length) {
      setFilter(filters)
    }
  }, [filters])

  return (
    <div>
      <table
        {...getTableProps()}
        className="nc-table"
        style={inlineFooter ? {} : { marginBottom: "60px" }}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  className="column-header"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="column-container">
                    <NCText>
                      <Box fontWeight={600}>{column.render("Header")}</Box>
                    </NCText>
                    <div className="sort-container">
                      {column.disableSortBy ? null : (
                        <React.Fragment>
                          <NCSvgIcon
                            component={AscendingIcon}
                            inheritViewBox
                            className={clsx("sorting-icon", {
                              "svg-primary":
                                column.isSorted && !column.isSortedDesc
                            })}
                          />
                          <NCSvgIcon
                            component={DescendingIcon}
                            className={clsx("sorting-icon", {
                              "svg-primary":
                                column.isSorted && column.isSortedDesc
                            })}
                            inheritViewBox
                          />
                        </React.Fragment>
                      )}
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {/* <NCLoader open={isLoading} /> */}
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (cell?.column?.id === "email" && cell?.value) ||
                    (cell?.column?.id === "company_email_address" &&
                      cell?.value) ? (
                    <NCTooltip title={cell.render("Cell")}>
                      <td {...cell.getCellProps()} style={{}}>
                        <NCText variant="body2" className="table-cell">
                          {cell.render("Cell")}
                        </NCText>
                      </td>
                    </NCTooltip>
                  ) : (
                    <td {...cell.getCellProps()} style={{}}>
                      <NCText variant="body2" className="table-cell">
                        {cell.render("Cell")}
                      </NCText>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {hidePagination ? null : (
        <Footer
          onPrev={onPrev}
          onNext={onNext}
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPage={page}
          inlineFooter={inlineFooter}
        />
      )}
    </div>
  )
}

export default NCTable
