import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import NCTheme from "./styles/NCTheme";
import App from "./App";
import "./styles/index.scss";

ReactDOM
    .createRoot(document.getElementById("root"))
    .render(
        <BrowserRouter>
            <ThemeProvider theme={NCTheme}>
                <App />
            </ThemeProvider>
        </BrowserRouter>
    );
