import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Grid, makeStyles, Paper } from "@material-ui/core"
import NCContent from "../shared/NCContent"
import NCTitle from "../shared/NCTitle"
import NCText from "../shared/NCText"
import NCButton from "../shared/NCButton"
import NCLoader from "../shared/NCLoader"
import AuthComponent from "../components/AuthComponent"
import { ReactComponent as EmailIcon } from "../assets/icons/email.svg"

import { Form, useFormik, FormikProvider } from "formik"
import * as Yup from "yup"
import { useLogin, useSendOtp } from "../services/mutations"
import FormField from "../components/FormField"
import NCCheckbox from "../shared/NCCheckbox"
import NCPasswordInput from "../shared/NCPasswordInput"
import AuthenticationPage from "./AuthenticationPage"
import withContext from "../components/DrawerConsumer"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: "24px 30px",
    borderRadius: "20px",
    flexWrap: "wrap",
    [theme.breakpoints.up("xl")]: {
      padding: "40px 50px"
    }
  },
  title: {
    marginTop: "12px",
    marginBottom: "25px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "40px",
      marginTop: "20px",
      marginBottom: "41px"
    }
  },
  forgotPassword: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end"
  },
  terms: {
    fontSize: "12px",
    paddingBottom: "10px",
    paddingTop: "10px",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
      paddingBottom: "18px",
      paddingTop: "18px"
    }
  },
  checkbox: {
    padding: "0px"
  },
  errorMessage: {
    fontSize: "12px",
    color: "red",
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px"
    }
  },
  customizedfield: {
    [theme.breakpoints.up("xl")]: {
      "& .MuiFormLabel-root": {
        fontSize: "23px"
      },
      "& .MuiInputBase-input": {
        fontSize: "23px"
      },
      "& .MuiFormHelperText-root": {
        fontSize: "20px"
      },
      "& .MuiButton-label": {
        fontSize: "23px"
      }
    }
  },
  forgot: {
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiTextField-root": {
      margin: "0px"
    },
    "& .MuiFormLabel-root": {
      marginTop: "15px",
      marginBottom: "5px"
    },
    "& .PrivateSwitchBase-input-25": {
      opacity: "100%",
      position: "relative"
    }
  }
}))

const LoginPage = props => {
  const navigate = useNavigate()
  const cls = useStyles()
  const loginRequest = useLogin()
  const sendOtpRequest = useSendOtp()
  const [isLoginDone, setIsLoginDone] = useState(false)

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      checkbox: false
    },
    validationSchema: Yup.object().shape({
      username: Yup.string()
        .email("Invalid email address")
        .required("Email address is required"),
      password: Yup.string().required("Password is required"),
      checkbox: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      )
    }),
    onSubmit(values) {
      loginRequest.mutate(values, {
        onSuccess: response => {
          if (response?.data?.data?.user?.is_two_factor_enabled === false) {
            navigate("/overview")
          } else {
            sendOtpRequest.mutate(response?.data, {
              onSuccess: () => {
                props.setEmailMessage(
                  "Please check your inbox and spam/junk folder for the verification code email."
                )
                props.showEmailModal(true)
                setIsLoginDone(true)
              }
            })
          }
        }
      })
    }
  })

  return (
    <>
      {!isLoginDone ? (
        <AuthComponent>
          <Grid item xs={8}>
            <Paper elevation={0} className={cls.paper}>
              <NCTitle className={cls.title}>Login</NCTitle>
              <FormikProvider value={formik}>
                <Form onSubmit={formik.handleSubmit}>
                  <FormField
                    label={"Email Address"}
                    name={"username"}
                    startIcon={EmailIcon}
                    required={true}
                    error={formik?.errors?.username}
                    className={cls.customizedfield}
                  />
                  <FormField
                    as={NCPasswordInput}
                    label={"Password"}
                    name={"password"}
                    type={"password"}
                    required={true}
                    error={formik?.errors?.password}
                    className={cls.customizedfield}
                  />
                  <Box
                    className={cls.forgotPassword}
                    onClick={() => navigate("/resetpassword")}
                  >
                    <NCContent
                      style={{ color: "#1EAAC4" }}
                      className={`cursor-pointer ${cls.forgot}`}
                    >
                      Forgot Password?
                    </NCContent>
                  </Box>
                  <NCText className={cls.terms}>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box mr={0.5} display={"flex"}>
                        <NCCheckbox
                          color={"primary"}
                          name={"checkbox"}
                          value={formik.values.checkbox}
                          onChange={e =>
                            formik.setFieldValue("checkbox", e.target.checked)
                          }
                        />
                      </Box>
                      <Box>
                        I have read and agreed to the following policies -
                        <span style={{ color: "#1EAAC4" }}>
                          {" "}
                          <span
                            onClick={() => window.open("/privacy-policy")}
                            className="cursor-pointer"
                          >
                            Privacy Policy
                          </span>
                          ,{" "}
                          <span
                            onClick={() => window.open("/terms-conditions")}
                            className="cursor-pointer"
                          >
                            Terms & Conditions
                          </span>
                        </span>{" "}
                        before proceeding.
                      </Box>
                    </Box>
                    <Box>
                      {formik.errors.checkbox ? (
                        <Box color={"#f44336"} mx={"14px"}>
                          {formik.errors.checkbox}
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                    {/* <NCCheckbox required={true} color={"primary"} /> */}
                  </NCText>
                  <NCButton
                    type="submit"
                    variant="contained"
                    className={cls.customizedfield}
                    color="primary"
                  >
                    Login
                  </NCButton>
                  {loginRequest.isLoading && <NCLoader open={true} />}
                </Form>
              </FormikProvider>
            </Paper>
          </Grid>
        </AuthComponent>
      ) : (
        <AuthenticationPage userCredentials={formik.values} />
      )}
    </>
  )
}

export default withContext(LoginPage)
